<template>
    <router-link :to="toPath" class="product-preview" :class="onSale ? 'sale' : ''">

        <product-image :photo="photo" :lazy="lazyLoad" :preview="true">
            <font-awesome-icon
                v-if="photo.variables.hasOwnProperty('categories') && photo.variables.categories.split('||').indexOf('147') >= 0"
                icon="fa-sharp fa-solid fa-bookmark" class="private" />

            <figcaption>
                <strong>
                    {{ photo.pagetitle }}
                </strong>

                <div class="tools">
                    <span class="prices" v-if="[8, 30].indexOf(photo.template) >= 0">
                        {{ getTranslation('price_varying_prefix') }}

                        <s v-if="onSale">
                            {{ asMoney(minPrice) }}
                        </s>

                        <span v-if="onSale">
                            {{ asMoney(minPriceSale) }}
                        </span>

                        <span v-else>
                            {{ asMoney(minPrice) }}
                        </span>

                        {{ currency }}
                    </span>

                    <span class="prices" v-else>
                        <s v-if="onSale">
                            {{ asMoney(photo.variables.price) }}
                        </s>

                        <span v-if="onSale">
                            {{ asMoney(photo.variables.salePrice) }}
                        </span>

                        <span v-else>
                            {{ asMoney(photo.variables.price) }}
                        </span>

                        {{ currency }}
                    </span>

                    <button class="add-to-cart" tabindex="-1" :aria-label="getTranslation('btn_add_to_cart')">
                        <font-awesome-icon icon="fa-sharp fa-solid fa-magnifying-glass" />
                    </button>
                </div>
            </figcaption>
        </product-image>

    </router-link>
</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

import ProductImage from '@/components/ProductImage.vue';

var vm;

export default {
    props: {
        index: {
            type: Number,
            default: -1,
        },
        photo: Object,
    },


    components: {
        ProductImage,
    },


    data()
    {
        return {
            minPrice: 0,
            minPriceSale: 0,
        };
    },


    computed: {
        ...mapGetters([
            'currency',
            'prints',
        ]),


        lazyLoad()
        {
            return vm.index != 0;
        },

        
        onSale()
        {
            return vm.photo.variables.onSale && vm.photo.variables.onSale == 1;
        },


        toPath()
        {
            let name;

            switch (vm.photo.template)
            {
                case 8:
                    name = 'PrintPage';
                    break;

                case 27:
                    name = 'PresetPage';
                    break;

                case 30:
                    name = 'SetPage';
                    break;

                default:
                    // name = 'ProductPage' + vm.$route.params.lang.toUpperCase();
                    name = 'ProductPageEN';
                    break;
            }

            return {
                name: name,
                params: {
                    alias: vm.photo.alias,
                },
            };
        },
    },

    
    setup()
	{
		const { asMoney, getMinPrice, getTranslation } = useFunctions();


		return {
            asMoney,
			getMinPrice,
            getTranslation,
		};
	},


    created()
    {
        vm = this;

        vm.minPrice = vm.photo.template == 8 ? vm.getMinPrice(vm.photo, false) : vm.photo.variables.price;
        vm.minPriceSale = vm.photo.template == 8 ? vm.getMinPrice(vm.photo, true) : vm.photo.variables.priceSale;
    },
}
</script>