<template>
    <form>

        <transition name="fade" mode="out-in">
        
            <loader v-if="processing" />
        
        </transition>


        <h4>
            {{ getTranslation('title_discount')  }}
        </h4>

        <ul>
            <li>
                <input type="text" v-model="code" :placeholder="getTranslation('enter_discount_code')" />
            </li>

            <li>
                <a href="#" class="button" :class="code.length > 0 ? '' : 'disabled'" @click.prevent="submitForm">
                    {{ getTranslation('apply_discount_code') }}
                </a>
            </li>
        </ul>
    </form>
</template>


<script>
import axios from 'axios';

import { mapActions, mapGetters } from 'vuex';

import LoaderElement from '@/components/LoaderElement.vue';

// import useCart from '@/hooks/cart.js';
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    components: {
        Loader: LoaderElement,
    },


    data()
    {
        return {
            code: '',
            processed: false,
            submitted: false,
            success: null,
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'isDev',
        ]),


		processing()
		{
			return vm.processed !== vm.submitted;
		},
    },


    methods: {
        ...mapActions([
            'updateDiscount',
        ]),


        async submitForm()
		{
			vm.submitted = true;

            let formData = {
                action: 'applyDiscount',
                data: JSON.stringify({
                    code: vm.code,
                }),
            };
            
			if (vm.isDev)
			{
				setTimeout(() => {
					vm.success = true;
					vm.processed = true;
                    vm.updateDiscount({
                        id: 1,
                        code: 'test',
                        value: 10,
                        type: 'PERC',
                        expiration_date: '2022-12-31',
                        uses: '1',
                    })
				}, 1300);
			}
			else
			{
				await axios.post(vm.base + 'api/forms/', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				})
				.then(response => {
                    vm.processed = true;
					vm.success = response.data.success;
                    console.log(response.data)
                    vm.updateDiscount(response.data.success)
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},
    },


    setup() {
        const { getTranslation } = useFunctions();
        // const { cartInfo, getCartItemName, getCartItemSettings } = useCart();


        return {
            getTranslation,
        };
    },


    created()
    {
        vm = this;
    },
}
</script>