<template>
    <form class="form">

        <product-meta :product="product" />

        <div v-if="description.length > 0 && product.template != 8" class="description" v-html="description" />

        <ul class="print-options">
            <slot name="materials"></slot>
            <slot name="sizes"></slot>

            <li>
                <div v-if="!isDigital" class="quantity-controls">
                    <a href="#" @click.prevent="changeQuantity(-1)">
                        <font-awesome-icon icon="fa-solid fa-minus" />
                    </a>
                    <span v-html="quantity" />
                    <a href="#" @click.prevent="changeQuantity(1)">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </a>
                </div>

                <label v-else>
                    {{ getTranslation('price') }}
                </label>

                <span class="money">
                    <s v-if="onSale">
                        {{ asMoney(productPrice) }}
                    </s>

                    <span v-if="onSale">
                        {{ asMoney(productPriceSale) }}
                    </span>

                    <span v-else>
                        {{ asMoney(productPrice) }}
                    </span>

                    {{ currency }}
                </span>
            </li>
        </ul>


        <div class="tools">
            <a href="#" class="button add-to-cart" :class="cartButtonState ? '' : 'disabled'"
                @click.prevent="addToCart">
                <font-awesome-icon icon="fa-regular fa-cart-plus" />

                {{ getTranslation('btn_add_to_cart') }}
            </a>

            <router-link class="button neutral" id="back-button" :to="backLink">
                <font-awesome-icon icon="fa-solid fa-arrow-left-long-to-line" />

                <span>
                    {{ getTranslation('btn_back') }}
                </span>
            </router-link>
        </div>


        <PaymentMethods />


        <div class="description" v-if="[8, 30].indexOf(product.template) >= 0 && description.length > 0">
            <strong>
                {{ getTranslation('description') }}
            </strong>

            <p v-html="description" />
        </div>

        <div v-if="product.template != 27" class="tab">
            <h3 v-html="getTranslation('title_shipping_return')" />

            <div class="small" v-html="shippingAndReturn" />
        </div>

        <div v-if="product.template == 27" class="tab">
            <h3 v-html="getTranslation('title_downloads')" />

            <div class="small" v-html="downloadsRefund" />
        </div>
    </form>
</template>


<script>
import useFunctions from '@/hooks/functions.js';

import { mapGetters } from 'vuex';

import PaymentMethods from '@/components/PaymentMethods.vue';
import ProductMeta from '@/components/ProductMeta.vue';

var vm;

export default {
    props: {
        cartButtonState: Boolean,
        product: Object,
        productPrice: Number,
        productPriceSale: Number,
        quantity: Number,
    },


    components: {
        PaymentMethods,
        ProductMeta,
    },


    computed: {
        ...mapGetters([
            'currency',
            'texts',
        ]),


        backLink()
        {
            let rName = 'PrintsOverview';

            switch (vm.product.template)
            {
                case 15:
                    // rName = 'ProductsOverview' + vm.$route.params.lang.toUpperCase();
                    rName = 'ProductsOverviewEN';
                    break;

                case 27:
                    rName = 'PresetsOverview';
                    break;

                case 30:
                    rName = 'SetsOverview';
                    break;
            }

            return { name: rName };
        },


        description()
        {
            let desc = vm.product.content;

            // if (vm.$route.params.lang != 'en' && [8, 27].indexOf(vm.product.template) >= 0)
            // {
            //     let key = 'content' + vm.$route.params.lang.toUpperCase();

            //     if (Object.prototype.hasOwnProperty.call(vm.product.variables, key))
            //     {
            //         desc = vm.product.variables[key];
            //     }
            // }
            
            return desc;
        },


        downloadsRefund()
        {
            // return vm.texts[vm.$route.params.lang].downloadsRefundText;
            return vm.texts.downloadsRefundText;
        },


        isDigital()
        {
            let d = false;

            if (vm.product.template == 27) d = true;

            return d;
        },


        onSale()
        {
            return vm.product.variables.onSale == 1
        },


        shippingAndReturn()
        {
            // return vm.texts[vm.$route.params.lang].shippingAndReturnText;
            return vm.texts.shippingAndReturnText;
        },
    },


    methods: {
        addToCart()
        {
            vm.$emit('addToCart');
        },

        
        changeQuantity(modifier)
        {
            vm.$emit('changeQuantity', modifier);
        },
    },


    setup()
    {
        const { asMoney, getTranslation } = useFunctions();


        return {
            asMoney,
            getTranslation,
        };
    },


    created()
    {
        vm = this;
    },
}
</script>