<template>
    <ul class="select-box dropdown" :class="{ open: open }">
        
        <li class="value">
            <slot></slot>

            <font-awesome-icon icon="fa-solid fa-caret-down" @mouseup="caretToggle" />
        </li>
        

        <li v-for="(o, i) of items" :key="i" :class="{ selected: value == o.iso }" @click="selected = o.iso; $emit('select', o.iso);">
            <span v-html="o.country" />
        </li>

    </ul>
</template>

<script>
import useFunctions from '@/hooks/functions.js';

// var vm;

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        open: Boolean,
        value: {
            type: String,
            required: false,
            default: null,
        },
    },
    
    
    data()
    {
        return {
            selected: this.default ? this.default : this.items.length > 0 ? this.items[0].iso : null,
        };
    },

    
    setup()
	{
		const { getTranslation } = useFunctions();


		return {
            getTranslation,
		};
	},


    // created()
    // {
    //     vm = this;
    // },
    
    
    // mounted()
    // {
    //     vm.$emit("select", vm.selected);
    // },
};
</script>
