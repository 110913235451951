<template>
    <nav>
        <slot></slot>

        <ul>
            <li v-if="showMain">
                <router-link :to="to(accountPage)">
                    {{ accountPage.menutitle }}
                </router-link>
            </li>
            
            <li v-for="(tab, id) in nav" :key="id">
                <router-link :to="to(tab)">
                    {{ tab.pagetitle }}
                </router-link>
            </li>

            <li>
                <a href="#" @click.prevent="$emit('logout')">
                    {{ getTranslation('logout') }}
                </a>
            </li>
        </ul>
    </nav>
</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        showMain: {
            type: Boolean,
            default: true,
        },
    },


    computed: {
        ...mapGetters([
            'accountPage',
        ]),


        nav()
        {
            let nav = [];

            for (let i = 0; i < vm.accountPage.sections.length; i++)
            {
                let p = vm.accountPage.sections[i];

                if (p.hidemenu != 1) nav.push(p);
            }
            
            return nav;
        }
    },

    
    setup()
	{
		const { getTranslation, to } = useFunctions();


		return {
            getTranslation,
            to,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>