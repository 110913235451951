<template>

    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main v-if="photo">
                <section class="product">
                    <div class="img has-slider">
                        <div class="slider">
                            <div class="slide" v-for="(img, id) in images" :key="id" :class="id == index ? 'active' : ''">
                                <product-image :photo="img" />
                            </div>
                        </div>
                    </div>
        
        
                    <product-form :cartButtonState="cartButtonState" :product="photo" :productPrice="productPrice"
                        :quantity="quantity" @addToCart="addToCart" @changeQuantity="changeQuantity" />
        
                </section>
        
        
                <section v-for="(set, sid) in photo.images" :key="['set', sid].join('-')">
                    <h2>
                        {{ set.seotitle }}
                    </h2>
        
                    <div class="before-afters">
                        <before-after v-for="(img, id) in set.images" :key="['example', sid, id].join('-')" :image="img"
                            :lazy="id > 1" />
                    </div>
                </section>
            </main>
        
            <main v-else>
                <section>
                    <font-awesome-icon icon="fa-sharp fa-regular fa-spinner-third" spin size="2xl" />
                </section>
            </main>


            <slot name="footer" />
        </div>
    </div>

</template>


<script>
import supportsWebP from 'supports-webp';

import { mapActions } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import useFunctions from '@/hooks/functions.js';

import BeforeAfter from '@/components/BeforeAfter.vue';
import ProductForm from '@/components/ProductForm.vue';
import ProductImage from '@/components/ProductImage.vue';

var vm;

export default {
    props: {
        page: Object,
        photo: Object,
    },


    components: {
        BeforeAfter,
        ProductForm,
        ProductImage,
    },


    data()
    {
        return {
            adding: false,
            defaults: {
                quantity: 1,
            },
            index: 0,
            quantity: 1,
            smoother: null,
            webp: true,
        };
    },


    computed: {
        cartButtonState()
        {
            if (vm.adding) return false;
            if (vm.quantity < 1) return false;

            return true;
        },
        

        images()
        {
            let imgs = [
                vm.photo,
            ];

            return imgs;
        },


        productPrice()
        {
            return vm.photo.variables.price * vm.quantity;
        },


        selected()
        {
            let po = vm.printOptions[0];
            
            for (let i = 0; i < vm.printOptions.length; i++)
            {
                let p = vm.printOptions[i];
                
                if (p.id == vm.printOption) po = p;
            }

            return {
                material: vm.materialOptions[vm.material],
                printOption: po,
            };
        },
    },


    methods: {
        ...mapActions([
            'addCartItems',
        ]),


        addToCart()
        {
            if (vm.cartButtonState)
            {
                let cartObjects = [];

                cartObjects.push({
                    photo: 0,
                    product: vm.photo.id,
                    quantity: 1,
                    digital: 1,
                });

                vm.adding = true;

                vm.addCartItems(cartObjects)
                .then(() => {
                    vm.adding = false;
                    vm.$emit('cartUpdate');
                })
            }
        },


        changeQuantity(modifier)
        {
            let q = vm.quantity + modifier;

            if (q < 1) q = 1;

            vm.quantity = q;
        },


        resetDefaults()
        {
            vm.quantity = vm.defaults.quantity;
        },


        toImg(e)
        {
            let target = e.currentTarget;

            if (target.className.indexOf('disabled') < 0)
            {
                vm.index += parseInt(target.dataset.modifier);
            }
        },
    },

    
    setup()
	{
		const { asMoney, getTranslation } = useFunctions();


		return {
            asMoney,
            getTranslation,
		};
	},


    created()
    {
        vm = this;

        supportsWebP.then(supported => {
            vm.webp = supported;
        });

        vm.resetDefaults();
    },


    mounted() {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })
    },
}
</script>