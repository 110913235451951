<template>
	<transition-group name="load" mode="out-in">
		<article v-if="loaded" key="path" :style="userZoom" @click="onClick">
			<teleport to="head">
				<title>
					{{ meta.title }}
				</title>
				
				<meta name="description" :content="meta.description">
				<meta name="keywords" :content="meta.keywords">
				
				<meta property="og:description" :content="meta.description">
				<meta property="og:title" :content="meta.title" />
				<meta property="og:image" :content="meta.image" />
			</teleport>
			

			<app-header
                :showAccountTab="showAccountTab"
                :showCartTab="showCartTab"
                :showNav="showNav"
                @logout="logoutUser"
                @toggle="toggleNav"
                @toggleTab="toggleTab"
            />
			
			
			<router-view v-if="typeof(page) == 'object' && Object.keys(page).length > 0" v-slot="{ Component }">
				<transition name="fade" mode="out-in">
					<component
						:is="Component"
                        :class="isMobile ? 'mobile' : 'desktop'"
						:key="routePath"
						:page="page"
						:photo="photo"
                        :webp="webp"
                        @cartUpdate="showCartUpdate"
						@logout="logoutUser"
                    >
                        <template v-slot:footer>
                            <app-footer />
                        </template>

                    </component>
				</transition>
			</router-view>
			

			
		</article>


		<loader-element v-else key="loader" @animated="animated = true" />

	</transition-group>
</template>


<script>
import supportsWebP from 'supports-webp';

import { mapActions, mapGetters } from 'vuex';

import usePage from '@/hooks/page.js';

import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/AppHeader.vue';
// import CartAnimation from '@/components/CartAnimation.vue';
import LoaderElement from '@/components/LoaderElement.vue';

var vm;

export default {
	components: {
		AppFooter,
		AppHeader,
		// CartAnimation,
		LoaderElement,
	},

	
	data()
	{
		return {
            animated: false,
            isMobile: null,
            keysPressed: [],
			product: null,
			showAccountTab: false,
			showCartTab: false,
			showNav: false,
            webp: true,
            zoom: 1,
		};
	},


	computed: {
		...mapGetters([
			'base',
			'duration',
			'pages',
			'siteName',
			'structure',
			'user',
		]),


		loaded()
		{
			let s = Object.keys(vm.structure),
				p = Object.keys(vm.pages);
			
			return s.length > 0 && p.length > 0 && vm.animated;
		},


		meta()
		{
			// If no page is loaded yet, return false
			if (Object.keys(vm.page).length === 0 || Object.prototype.hasOwnProperty.call(vm.page, 'temp')) return false;

			let view = vm.photo !== null ? vm.photo : vm.page;


			let title = view.seotitle;
			title += ' | ' + vm.siteName;

			let img = view.hasImage ? view.variables.image : 'img/icons/android-chrome-512x512.png';
			
			let meta = {
				description: view.template == 8 && vm.$route.params.lang == 'nl' ? view.introtext : view.description,
				keywords: view.keywords,
				title: title,
				image: vm.base + img,
			};
			

			return meta;
		},


		routePath()
		{
			let p = vm.$route.fullPath;

			// Make adjustments for modal child routes
            if (vm.$route.name == "MyAccountTab" || vm.$route.name == "ViewOrder")
			{
				p = p.split('/account/')[0] + '/account';
			}
			// else if (vm.$route.name == "PrintPage")
			// {
			// 	p = p.split('/prints/')[0] + '/prints';
			// }
			// else if (vm.$route.name == "ProductEN")
			// {
			// 	p = p.split('/photo-gifts/')[0] + '/photo-gifts';
			// }
			// else if (vm.$route.name == "ProductNL")
			// {
			// 	p = p.split('/fotoproducten/')[0] + '/fotoproducten';
			// }
			

			return p;
		},

        
        userZoom()
        {
            return `--zoom: ${vm.zoom}`;
        },
	},


	methods: {
		...mapActions([
			'_initialize',
            '_loadContent',
			'loadCountries',
			'logout',
        ]),



        detectTouch()
        {
            try {
                document.createEvent('TouchEvent');
                vm.isMobile = true;
            }
            catch (e)
            {
                vm.isMobile = false;
            }
        },


		logoutUser()
		{
			// Do a minor delay for the general "onClick" listener on the DOM
			setTimeout(() => {
				vm.logout();
				vm.$router.push({ name: 'HomePage' })
			}, 13);
		},


		onClick(e)
		{
            let clickables = [
                'a',
                'button',
                'input',
            ],
            target = e.target,
            tag = target.nodeName.toLowerCase();

			if (target != null)
			{
                while (
                    (
                        ['path', 'svg'].indexOf(tag) >= 0 ||
                        (target.className.indexOf('has-tab') < 0 && target.className.indexOf('button') < 0)
                    ) &&
                    tag != 'body' &&
                    target.parentNode != null &&
                    clickables.indexOf(tag) < 0
                )
                {
                    target = target.parentNode;
                    tag = target.nodeName.toLowerCase();
				}

                let path = target.href ? target.href.replace(target.baseURI, '/') : null;
                // let path = target.href ? target.href.replace(target.baseURI, '/') : null,
                //     lang = `/${vm.$route.params.lang}/`;

                switch (tag)
                {
                    case 'a':
                        if (target.className.indexOf('link') >= 0 && target.className.indexOf('router-link') < 0)
                        {
                            e.preventDefault();
                            
                            // Trim the trailing slash
                            if (path[path.length - 1] == '/') path = path.substr(0, path.length - 1);
                            
                            // If the language parameter is missing, add it forcefully
                            // if (path.indexOf(lang) < 0) path = (lang + path).replace('//', '/');

                            // Go the the path
                            vm.$router.push(path);
                        }

                        break;

                    case 'body':
                        vm.showAccountTab = false;
                        vm.showCartTab = false;
                        break;
                }
			}
		},


		showCartUpdate()
		{
            vm.showCartTab = true;
		},


        // registerKeyPress(e)
        // {
        //     // TODO: Get this to work properly

        //     // if (vm.keysPressed.indexOf(e.key) < 0) vm.keysPressed.push(e.key);

        //     // if ((vm.keysPressed.indexOf('Control') >= 0 || vm.keysPressed.indexOf('Command') >= 0))
        //     // {
        //     //     e.preventDefault()

        //     //     if (e.key == '+')
        //     //     {
        //     //         vm.zoom += 0.1;
        //     //     }
        //     //     else if (e.key == '-')
        //     //     {
        //     //         vm.zoom -= 0.1;
        //     //     }
        //     //     else if (e.key == '0')
        //     //     {
        //     //         vm.zoom = 1;
        //     //     }
        //     // }
        // },

		
        // resetKeysPressed()
        // {
        //     vm.keysPressed = [];
        // },


		scrollReveal()
		{
            vm.detectTouch();

			let reveals = document.querySelectorAll('.reveal');
			
			for(let i = 0; i < reveals.length; i++){

				let wHeight = window.innerHeight;
				let revealTop = reveals[i].getBoundingClientRect().top;
				let revealPoint = Math.round(window.innerHeight / 4);

				if(revealTop < wHeight - revealPoint)
				{
					reveals[i].classList.add('active');
				}
				else
				{
					reveals[i].classList.remove('active');
				}
			}
		},


		// setProduct(p)
		// {
		// 	vm.product = p;
		// },


		toggleNav()
		{
			vm.showNav = !vm.showNav;
		},


		toggleTab(id)
		{
			switch (id)
			{
				case 'account':
					vm.showAccountTab = !vm.showAccountTab;
					break;

				case 'cart-preview':
					vm.showCartTab = !vm.showCartTab;
					break;
			}
		},
	},


	setup()
	{
		const { page, photo } = usePage();


		return {
			page,
			photo,
		};
	},


	created()
	{
        vm = this;


        // Detect whether or not this is a touch device
        vm.detectTouch();
		

        // Make sure webp support is checked
        supportsWebP.then(supported => {
            if(!supported)
            {
                document.documentElement.classList.remove('webp');
                document.documentElement.classList.add('no-webp');
                vm.webp = false;
            }
            else
            {
                document.documentElement.classList.remove('no-webp');
                document.documentElement.classList.add('webp');
                vm.webp = true;
            }
        });


        // Initialize the actual application
        // let lang = Object.prototype.hasOwnProperty.call(vm.$route.params, 'lang') ? vm.$route.params.lang : 'en';
        let lang = 'en';

		vm._initialize({
            lang: lang,
            request: 'main',
        })
		.then(() => {
			// if(!Object.prototype.hasOwnProperty.call(vm.$route.params, 'lang'))
			// {
			// 	vm.$router.push({
			// 		name: 'HomePage',
			// 		params: {
			// 			lang: lang,
			// 		}
			// 	});
			// }
            // else if (vm.$route.path.slice(-1) == '/')
            if (vm.$route.path.slice(-1) == '/')
            {
                let p = vm.$route.path.slice(0, -1);
                history.replaceState(null, '', p)
            }
		});

        vm._initialize({ request: 'client' });
        vm._initialize({ request: 'language' });
        vm._initialize({ request: 'main' });
        vm._initialize({ request: 'order' });
        vm._initialize({ request: 'products' });
        vm._initialize({ request: 'shop' });

		window.addEventListener('scroll', vm.scrollReveal);
		window.addEventListener('resize', vm.scrollReveal);
        // window.addEventListener('keydown', vm.registerKeyPress);
        // window.addEventListener('keyup', vm.resetKeysPressed);
	},


	mounted()
	{
		setTimeout(() => {
            vm.scrollReveal();
        }, vm.duration);
	},
	

	unmounted()
	{
		window.removeEventListener('scroll', vm.scrollReveal);
		window.removeEventListener('resize', vm.scrollReveal);
        // window.removeEventListener('keydown', vm.registerKeyPress);
        // window.removeEventListener('keyup', vm.resetKeysPressed);
	},


    watch: {
        "$route.params.lang" (to)
        {
            // If the languge changes, update the language on the HTML tag
            document.documentElement.setAttribute('lang', to);
        },


        $route ()
        {
            vm.showNav = false;
        },


        page()
        {
            vm.showAccountTab = false;
            vm.showCartTab = false;
        },


		showAccountTab(to)
		{
			if (to)
			{
				vm.showCartTab = false;
			}
		},


		showCartTab(to)
		{
			if (to)
			{
				vm.showAccountTab = false;
			}
		},
    },
};
</script>


<style lang="scss">
@import 'assets/scss/app.scss';
</style>
