<template>

    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main v-if="loaded">
                <section class="product">
                    <div class="img has-slider">
                        <div class="slider">
                            <div class="slide" v-for="(img, id) in images" :key="id" :class="id == index ? 'active' : ''">
                                <product-image :photo="img" />
                            </div>
        
                            <a href="#" class="prev" data-modifier="-1" :class="index == 0 ? 'disabled' : ''"
                                @click.prevent="toImg">
                                <font-awesome-icon icon="fa-solid fa-angle-left" />
                            </a>
        
                            <a href="#" class="next" data-modifier="1" :class="index == images.length - 1 ? 'disabled' : ''"
                                @click.prevent="toImg">
                                <font-awesome-icon icon="fa-solid fa-angle-right" />
                            </a>
                        </div>
                    </div>
        
        
                    <product-form :cartButtonState="cartButtonState" :product="page" :productPrice="productPrice" :productPriceSale="productPriceSale"
                        :quantity="quantity" @addToCart="addToCart" @changeQuantity="changeQuantity" />
        
                </section>
            </main>
        
            <main v-else>
                <section>
                    <font-awesome-icon icon="fa-sharp fa-regular fa-spinner-third" spin size="2xl" />
                </section>
            </main>


            <slot name="footer" />
        </div>
    </div>

</template>


<script>
import supportsWebP from 'supports-webp';

import { mapActions } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import useFunctions from '@/hooks/functions.js';

import ProductForm from '@/components/ProductForm.vue';
import ProductImage from '@/components/ProductImage.vue';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        ProductForm,
        ProductImage,
    },


    data()
    {
        return {
            adding: false,
            defaults: {
                quantity: 1,
            },
            index: 0,
            quantity: 1,
            smoother: null,
            webp: true,
        };
    },


    computed: {
        cartButtonState()
        {
            if (vm.adding) return false;
            if (vm.quantity < 1) return false;

            return true;
        },
        

        images()
        {
            let imgs = [
                vm.page,
            ];

            for (let i = 0; i < vm.page.images.length; i++)
            {
                imgs.push(vm.page.images[i]);
            }

            return imgs;
        },


        loaded()
        {
            return vm.page;
        },


        productPrice()
        {
            return vm.page.variables.price * vm.quantity;
        },


        productPriceSale()
        {
            return vm.page.variables.salePrice * vm.quantity;
        },


        selected()
        {
            let po = vm.printOptions[0];
            
            for (let i = 0; i < vm.printOptions.length; i++)
            {
                let p = vm.printOptions[i];
                
                if (p.id == vm.printOption) po = p;
            }

            return {
                material: vm.materialOptions[vm.material],
                printOption: po,
            };
        },
    },


    methods: {
        ...mapActions([
            'addCartItems',
        ]),


        addToCart()
        {
            if (vm.cartButtonState)
            {
                let cartObjects = [];

                cartObjects.push({
                    photo: 0,
                    product: vm.page.id,
                    quantity: vm.quantity,
                    digital: 0,
                });

                vm.adding = true;

                // vm.$nextTick(() => {
                    // setTimeout(() => {
                        vm.addCartItems(cartObjects)
                        .then(() => {
                            vm.adding = false;
                            vm.$emit('cartUpdate');
                        })
                    // }, 1.5 * vm.duration);
                // });
            }
        },


        changeQuantity(modifier)
        {
            let q = vm.quantity + modifier;

            if (q < 1) q = 1;

            vm.quantity = q;
        },


        resetDefaults()
        {
            vm.quantity = vm.defaults.quantity;
        },


        toImg(e)
        {
            let target = e.currentTarget;

            if (target.className.indexOf('disabled') < 0)
            {
                vm.index += parseInt(target.dataset.modifier);
            }
        },
    },

    
    setup()
	{
		const { asMoney, getTranslation } = useFunctions();


		return {
            asMoney,
            getTranslation,
		};
	},


    created()
    {
        vm = this;

        supportsWebP.then(supported => {
            vm.webp = supported;
        });

        vm.resetDefaults();
    },


    mounted()
    {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })
    },
}
</script>