<template>
    <a href="#" class="has-tab" id="cart-preview" :title="getTranslation('tip_cart_preview')" @click.prevent="onClick">

        <span v-html="cartInfo.size"></span>


        <font-awesome-icon icon="fa-regular fa-shopping-basket fa-lg" />


        <transition name="reveal-cart">
        
            <aside v-if="showTab">
                <a href="#" class="close" @click.prevent>
                    <font-awesome-icon icon="fa-solid fa-times" />
                </a>

                <h3>
                    {{ getTranslation('your_cart') }}
                </h3>

                <div v-if="cart.length > 0">
                    <ul class="cart-items">
                        <li v-for="(ci, id) in cart" :key="id" :style="getCartItemSettings(ci)">
                            <div class="img">
                                <figure class="size-1x1" />
                            </div>
                            
                            <strong class="name" v-html="getCartItemName(ci)" />

                            <strong class="price">
                                {{ currency }}
                            </strong>
                            
                            <span class="quantity">
                                {{ getTranslation('quantity') }}:
                            </span>
                            
                            <small @click="removeCartItem(id)">
                                {{ getTranslation('delete') }}
                            </small>
                        </li>
                    </ul>

                    <div class="totals">
                        <strong>
                            {{ getTranslation('total') }}:
                        </strong>

                        <strong>
                            {{ asMoney(cartInfo.total) }}
                            {{ currency }}
                        </strong>
                    </div>

                    <div class="tools">
                        <router-link :to="cartLink" class="cart-link">
                            {{ getTranslation('btn_cart_change') }}
                        </router-link>
                        
                        <router-link :to="orderLink" class="button add-to-cart">
                            <font-awesome-icon icon="fa-solid fa-wallet" />
                            
                            {{ getTranslation('btn_order') }}
                        </router-link>
                    </div>
                </div>

                <em v-else>
                    {{ getTranslation('warning_empty_cart') }}
                </em>
            </aside>

        </transition>

    </a>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import useCart from '@/hooks/cart.js';
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        showTab: Boolean,
    },


    computed: {
        ...mapGetters([
            'cart',
            'currency',
        ]),


        cartLink()
        {
            return {
                // name: 'Cart' + vm.$route.params.lang.toUpperCase(),
                name: 'CartEN',
            };
        },


        orderLink()
        {
            return {
                // name: 'Order' + vm.$route.params.lang.toUpperCase(),
                name: 'OrderEN',
            };
        },
    },


    methods: {
        ...mapActions([
            'removeCartItem',
        ]),


        close()
        {
            // vm.$emit('toggleTab', 'cart-preview');
        },


        onClick({target})
        {
            let tag = target.nodeName.toLowerCase();

            if (
                (tag == 'a' && target.className.indexOf('has-tab') >= 0) ||
                ['i', 'path', 'span', 'svg'].indexOf(tag) >= 0
            )
            {
                vm.$emit('toggleTab', 'cart-preview');
            }
        },
    },

    
    setup()
	{
		const { asMoney, getTranslation } = useFunctions();
        const { cartInfo, getCartItemName, getCartItemSettings } = useCart();


		return {
            asMoney,
            cartInfo,
            getCartItemName,
            getCartItemSettings,
            getTranslation,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>