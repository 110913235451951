<template>
    <div class="content container" v-if="order !== null">
        <h3 class="alt">
            {{ getTranslation('order') }}:
            
            <span>
                {{ order.id }}
            </span>
        </h3>


        <div class="addresses">
            
            <AddressPreview :address="getAddress(order.shipping_address)" :extended="true" :name="order.shipping_name">
                <h4>
                    {{ getTranslation('shipping_address') }}
                </h4>
            </AddressPreview>


            <AddressPreview :address="getAddress(order.order_address)" :extended="true" :name="order.order_name">
                <h4>
                    {{ getTranslation('billing_address') }}
                </h4>
            </AddressPreview>

        </div>


        <div class="status">
            <h4>
                {{ getTranslation('order_status') }}
            </h4>

            <span>
                {{ getOrderStatus(order) }}
            </span>
        </div>


        <h3 class="alt">
            {{ getTranslation('order_items') }}
        </h3>

        <ul class="list items">
            <OrderProduct v-for="(oi, id) in order.items" :key="id" :item="oi" />

            <li class="list-item totals">
                <span>
                    {{ getTranslation('subtotal')}}
                </span>

                <span>
                    {{ subtotal }}
                    {{ currency }}
                </span>
            </li>

            <li class="list-item totals vat">
                <span>
                    {{ getTranslation('vat')}}
                </span>

                <span>
                    {{ vat }}
                    {{ currency }}
                </span>
            </li>

            <li class="list-item totals total">
                <span>
                    {{ getTranslation('total')}}
                </span>

                <span>
                    {{ orderTotal }}
                    {{ currency }}
                </span>
            </li>
        </ul>

        <div class="tools">
            <div>
                <a href="#" @click.prevent="$router.go(-1)">
                    <font-awesome-icon icon="fa-solid fa-chevron-double-left" />
    
                    <span>
                        {{ getTranslation('back_to_orders') }}
                    </span>
                </a>
            </div>
            
            <a v-if="hasDigitals" href="#" class="button secondary" @click.prevent="downloadProducts">
                {{ getTranslation('download_digitals') }}
            </a>
            
            <a href="#" @click.prevent="downloadInvoice" class="button">
                {{ getTranslation('download_invoice') }}
            </a>
        </div>

    </div>
</template>


<script>
import { mapGetters } from 'vuex';

import AddressPreview from '@/components/AddressPreview.vue';
import OrderProduct from '@/components/OrderProduct.vue';
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    components: {
        AddressPreview,
        OrderProduct,
    },


    data()
    {
        return {
            order: null,
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'currency',
            'user',
        ]),


        hasDigitals()
        {
            for (let i = 0; i < vm.order.items.length; i++)
            {
                if (vm.order.items[i].digital == 1) return true;
            }

            return false;
        },
        

        orderTotal()
        {
            let t = vm.vatMath(vm.order.items);

            return vm.asMoney(t);
        },


        subtotal()
        {
            let st = vm.vatMath(vm.order.items, 'subtotal');

            return vm.asMoney(st);
        },


        vat()
        {
            let vat = vm.vatMath(vm.order.items, 'vat');

            return vm.asMoney(vat);
        },
    },



    methods: {
        downloadInvoice()
        {
            let url = vm.base + `shop/pdf/invoice/download/?order=${vm.user.id}x${vm.order.id}`;
            window.open(url, '_blank').focus();
        },
        

        downloadProducts()
        {
            let url = vm.base + `shop/downloads/?o=${vm.user.id}x${vm.order.id}`;
            window.open(url, '_blank').focus();
        },


        getAddress(id)
        {
            for (let i = 0; i < vm.user.addresses.length; i++)
            {
                let a = vm.user.addresses[i];

                if (a.id == id) return a;
            }

            return null;
        },


        getOrder()
        {
            for (let i = 0; i < vm.user.orders.length; i++)
            {
                let o = vm.user.orders[i];

                if (o.id == vm.$route.params.id) return o;
            }

            return null;
        },
    },

    
    setup()
	{
		const { asMoney, formatDate, getOrderStatus, getTranslation, vatMath } = useFunctions();


		return {
            asMoney,
            formatDate,
            getOrderStatus,
            getTranslation,
            vatMath,
		};
	},


    created()
    {
        vm = this;

        vm.order = vm.getOrder();
    },
}
</script>