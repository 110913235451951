<template>

    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main class="portfolio">
                <section>
                    <div>
                        <h1 v-html="page.pagetitle" />
                        <h2 v-if="page.longtitle.length > 0" v-html="page.longtitle" />
        
                        <div class="content" v-html="page.content" />
                    </div>
                </section>
        
        
                <section class="products gallery">
        
                    <aside class="filters">
                        <a href="#" class="filter-toggle" @click.prevent="showFilters = !showFilters">
                            <font-awesome-icon icon="fa-solid fa-filter" />
        
                            <span>
                                {{ getTranslation('filters') }}
                            </span>
                        </a>
        
                        <span>
                            <font-awesome-icon icon="fa-solid fa-filter" />
        
                            <span>
                                {{ getTranslation('filters') }}
                            </span>
                        </span>
        
                        <div class="filter-options" :class="showFilters ? 'show' : 'hide'">
                            <div class="filter-option" v-if="appliedFilters > 0">
                                <ul>
                                    <li>
                                        <a href="#" class="reset" @click.prevent="resetFilters">
                                            <font-awesome-icon icon="fa-solid fa-times" />
        
                                            <label>
                                                <span v-html="getTranslation('label_reset_filters')" />
                                            </label>
                                        </a>
                                    </li>
                                </ul>
                            </div>
        
        
                            <div class="filter-option">
                                <h3 v-html="getTranslation('filter_categories')" />
        
                                <ul>
                                    <li v-for="(c, id) in categories" :key="['cat', appliedFilters, id].join('-')"
                                        :class="!showFilter('categories', c.id) ? 'disabled' : ''">
                                        <input v-if="filterApplyCount('categories', c.id) > 0" type="checkbox" :value="c.id"
                                            :id="['cat', c.alias].join('-')" v-model="filters.categories" />
        
                                        <font-awesome-icon
                                            :icon="filters.categories.indexOf(c.id) >= 0 ? 'fa-solid fa-square' : 'fa-regular fa-square'"
                                            @click="clickLabel" />
        
                                        <label :for="['cat', c.alias].join('-')">
                                            <span v-html="c.pagetitle" />
        
                                            ({{ filterApplyCount('categories', c.id) }})
                                        </label>
                                    </li>
                                </ul>
                            </div>
        
        
                            <div class="filter-option">
                                <h3 v-html="getTranslation('filter_continents')" />
        
                                <ul>
                                    <li v-for="(c, id) in continentFilters" :key="['cnt', appliedFilters, id].join('-')"
                                        :class="!showFilter('continents', c) ? 'disabled' : ''">
                                        <input type="checkbox" :value="c" :id="['continent', c].join('-')"
                                            v-model="filters.continents" />
        
                                        <font-awesome-icon
                                            :icon="filters.continents.indexOf(c) >= 0 ? 'fa-solid fa-square' : 'fa-regular fa-square'"
                                            @click.self="clickLabel" />
        
                                        <label :for="['continent', c].join('-')">
                                            <span v-html="getTranslation(['continent', c].join('_'))" />
        
                                            ({{ filterApplyCount('continents', c) }})
                                        </label>
                                    </li>
                                </ul>
                            </div>
        
        
                            <div class="filter-option" v-if="hasColorOptions">
                                <h3 v-html="getTranslation('filter_color')" />
        
                                <ul>
                                    <li v-for="(c, id) in colorFilters" :key="['clr', appliedFilters, id].join('-')"
                                        :class="!showFilter('color', c) ? 'disabled' : ''">
                                        <input type="checkbox" :value="c" :id="['color', c].join('-')"
                                            v-model="filters.color" />
        
                                        <font-awesome-icon
                                            :icon="filters.color.indexOf(c) >= 0 ? 'fa-solid fa-square' : 'fa-regular fa-square'"
                                            @click="clickLabel" />
        
                                        <label :for="['color', c].join('-')">
                                            <span v-html="getTranslation(['color', c].join('_'))" />
        
                                            ({{ filterApplyCount('color', c) }})
                                        </label>
                                    </li>
                                </ul>
                            </div>
        
        
                            <div class="filter-option" v-if="hasLandscape">
                                <h3 v-html="getTranslation('filter_ratio')" />
        
                                <ul>
                                    <li v-for="(r, id) in ratioFilters" :key="['rat', appliedFilters, id].join('-')"
                                        :class="!showFilter('imgRatio', r) ? 'disabled' : ''">
                                        <input type="checkbox" :value="r" :id="['ratio', r].join('-')"
                                            v-model="filters.imgRatio" />
        
                                        <font-awesome-icon
                                            :icon="filters.imgRatio.indexOf(r) >= 0 ? 'fa-solid fa-square' : 'fa-regular fa-square'"
                                            @click="clickLabel" />
        
                                        <label :for="['ratio', r].join('-')">
                                            <span v-html="getTranslation(['ratio', r].join('_'))" />
        
                                            ({{ filterApplyCount('imgRatio', r) }})
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </aside>
        
        
                    <div class="items" :key="$route.path">
        
                        <transition-group name="pop-in">
                            <product-preview v-for="(pr, id) in products" :key="['product', pr.alias].join('-')" :index="id"
                                :photo="pr" />
                        </transition-group>
        
                        <font-awesome-icon v-if="products.length === 0" icon="fa-sharp fa-regular fa-spinner-third" spin
                            size="2xl" />
        
                    </div>
        
                </section>
        
            </main>


            <slot name="footer" />
        </div>
    </div>

</template>


<script>
import { mapGetters } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import useFunctions from '@/hooks/functions.js';

import ProductPreview from '@/components/ProductPreview.vue';

var vm;

export default {
    props: {
        page: Object,
        photo: Object,
    },


    components: {
        ProductPreview,
    },


    data()
    {
        return {
            colors: [
                'black_white',
                'full_color',
            ],
            continentFilters: [
                'africa',
                'antarctica',
                'asia',
                'europe',
                'north_america',
                'oceania',
                'south_america',
            ],
            filters: {
                'categories': [],
                'color': [],
                'continents': [],
                'imgRatio': [],
            },
            hasLandscape: false,
            ratioFilters: [
                'landscape',
                'portrait',
            ],
            showFilters: false,
            smoother: null,
        };
    },


    computed: {
        ...mapGetters([
            'categories',
            'photos',
        ]),


        appliedFilters()
        {
            let af = 0,
                keys = Object.keys(vm.filters);

            for (let i = 0; i < keys.length; i++)
            {
                let k = keys[i],
                    f = vm.filters[k];

                af += f.length;
            }

            return af;
        },


        colorFilters()
        {
            let cf = [],
                keys = [],
                values = {};
            
            for (let i = 0; i < vm.colors.length; i++)
            {
                let c = vm.colors[i],
                    key = vm.getTranslation('color_' + c);
                
                keys.push(key);
                values[key] = c;
            }

            keys.sort();

            for (let i = 0; i < keys.length; i++)
            {
                cf.push(values[keys[i]]);
            }

            return cf;
        },


        hasColorOptions()
        {
            let co = [];
            
            for (let i = 0; i < vm.products.length; i++)
            {
                let p = vm.products[i],
                    c = p.variables.color;

                if (co.indexOf(c) < 0) co.push(c);
                
            }
            
            return co.length > 1;
        },


        products()
        {
            let array = vm.photos,
                filtered = [],
                filters = Object.keys(vm.filters);

            // Start by looping through the products
            for (let i = 0; i < array.length; i++)
            {
                let allApply = true,
                    p = array[i];


                // Loop through all filters
                for (let j = 0; j < filters.length; j++)
                {
                    let f = filters[j];
                    
                    // Check if all filters apply
                    for (let k = 0; k < vm.filters[f].length; k++)
                    {
                        if (!vm.filterApplies(p, f, vm.filters[f][k]))
                        {
                            allApply = false;
                        }
                    }
                }


                // If all filters apply, add the product to the filtered list
                if (allApply)
                {
                    filtered.push(p);
                }
            }

            return filtered;
        },
    },


    methods: {
        clickLabel({target})
        {
            target.nextSibling.click();
        },


        filterApplies(product, field, value)
        {
            let v = product.variables[field];

            if (field == 'imgRatio')
            {
                let dim = v.split('x'),
                    h = dim[1],
                    w = dim[0];

                return (value == 'portrait' && w < h) || (value == 'landscape' && w > h) || (value == 'rectangle' && w == h);
            }
            else if (['categories', 'continents'].indexOf(field) >= 0)
            {
                // Convert to an array for potential multi-selects
                let tv = v.split('||');

                
                // If the filter does not apply, return false
                return tv.indexOf(value) >= 0;
            }
            else
            {
                return product.variables[field] == value;
            }
        },


        filterApplyCount(field, value)
        {
            let c = 0;

            // Loop through all remaining products
            for (let i = 0; i < vm.products.length; i++)
            {
                if (vm.filterApplies(vm.products[i], field, value)) c++;
            }

            return c;
        },


        resetFilters()
        {
            let f = vm.filters,
                keys = Object.keys(f);

            for (let i = 0; i < keys.length; i++)
            {
                f[keys[i]] = [];
            }

            vm.filters = f;
        },


        showFilter(field, value)
        {
            if (vm.filterApplyCount(field, value) == 0) return false;
            if (vm.filterApplyCount(field, value) == vm.photos.length) return false;

            return true;
        },
    },

    
    setup()
	{
		const { getTranslation } = useFunctions();


		return {
            getTranslation,
		};
	},


    created()
    {
        vm = this;
    },


    mounted() {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }

        })
    },
}
</script>