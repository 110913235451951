<template>

    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main>
                <section class="content white">
        
                    <div class="text">
        
                        <h1 v-html="page.pagetitle" />
                        <h2 v-if="page.longtitle.length > 0" v-html="page.longtitle" />
        
                        <div v-html="page.content" />
        
        
                        <div class="tools">
        
                            <router-link
                                v-if="page.variables.hasOwnProperty('ctaLink') && page.variables.hasOwnProperty('ctaText')"
                                class="button" :to="to(page.variables.ctaLink)">
                                {{ page.variables.ctaText }}
                            </router-link>
        
                        </div>
        
                    </div>
        
        
                    <div class="image">
                        <figure v-if="hasImage" :style="image" />
                    </div>
        
                </section>
            </main>


            <slot name="footer" />
        </div>
    </div>

</template>


<script>
import { mapGetters } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

var vm;

export default {
    props: {
        page: Object,
    },


    data()
    {
        return {
            smoother: null,
        }
    },


    computed: {
        ...mapGetters([
            'base',
            'pages',
        ]),


        hasImage()
        {
            return Object.prototype.hasOwnProperty.call(vm.page.variables, 'image');
        },


        image()
        {
            return vm.hasImage ? "background-image: url('" + vm.base + vm.page.variables.image + "')" : '';
        },


        // lang()
        // {
        //     return vm.$route.params.lang;
        // },
    },


    methods: {
        to(id)
        {
            // let pages = vm.pages[vm.lang];
            let pages = vm.pages;

            for (let i = 0; i < pages.length; i++)
            {
                let p = pages[i];

                if (p.id == id) return p.path;
            }

            return vm.$route;
        },
    },


    created()
    {
        vm = this;
    },


    mounted() {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })
    },
}
</script>