<template>
    <div class="tools" v-if="page.variables.hasOwnProperty('ctaText')">
        <a href="https://pascalmaranus.com/shop/api/transaction/again/" class="button">
            {{  page.variables.ctaText }}
        </a>
    </div>
</template>


<script>
// var vm;

export default {
    props: {
        page: Object,
    },
}
</script>