<template>
    <div class="content">
        <h3 class="alt">
            {{ getTranslation('my_orders') }}
        </h3>

        <nav class="pagination" v-if="pageCount > 1">
            <a href="#" @click.prevent="prev">
                <font-awesome-icon icon="fa-duotone fa-circle-arrow-left" />
            </a>

            <span :class="{ hidden: navStart <= 1 }">
                ...
            </span>

            <span class="clickable" v-for="(p, id) in nav" :key="['nav', id].join('-')" :class="{ active: p == pageID }">
                {{ p }}
            </span>

            <span :class="{ hidden: navEnd >= pageCount }">
                ...
            </span>

            <a href="#" @click.prevent="next">
                <font-awesome-icon icon="fa-duotone fa-circle-arrow-right" />
            </a>
        </nav>

        <div class="list orders">
            <div class="list-item headings">
                <div class="mobile">
                    #
                </div>

                <div class="mobile">
                    {{ getTranslation('order_date') }}
                </div>

                <div>
                    {{ getTranslation('shipping_to') }}
                </div>

                <div class="mobile text-right">
                    {{ getTranslation('order_total') }}
                </div>

                <div>
                    {{ getTranslation('order_status') }}
                </div>

                <div class="mobile tools"></div>
            </div>

            <div class="list-item" v-for="(o, id) in orders" :key="id">
                <div class="mobile">
                    <router-link :to="{ name: 'ViewOrder', params: { id: o.id } }" :title="getTranslation('view_order')">
                        {{ o.id }}
                    </router-link>
                </div>

                <div class="mobile">
                    {{ formatDate(o.order_date) }}
                </div>

                <div>
                    {{ o.shipping_name }}
                </div>

                <div class="mobile text-right">
                    {{ getOrderTotal(o) }}
                    {{ currency }}
                </div>

                <div>
                    {{ getOrderStatus(o) }}
                </div>

                <div class="mobile tools text-right">
                    <router-link :to="{ name: 'ViewOrder', params: { id: o.id } }" :title="getTranslation('view_order')">
                        <font-awesome-icon icon="fa-solid fa-eye" />
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    data()
    {
        return {
            navRadius: 2,
            pageID: 1,
            perPage: 10,
        };
    },


    computed: {
        ...mapGetters([
            'currency',
            'user',
        ]),


        end()
        {
            return Math.min(vm.pageID * vm.perPage, vm.user.orders.length);
        },


        orders()
        {
            let o = [];

            for (let i = vm.start; i < vm.end; i++)
            {
                o.push(vm.user.orders[i]);
            }

            return o;
        },


        nav()
        {
            let nav = [];

            for (let i = vm.navStart; i <= vm.navEnd; i++)
            {
                nav.push(i);
            }

            return nav;
        },


        navEnd()
        {
            let range = (vm.navRadius * 2) + 1,
                result = vm.pageID + vm.navRadius;

            if (result < range)
            {
                result = Math.min(range, vm.pageCount);
            }
            else if (result > vm.pageCount)
            {
                result = vm.pageCount;
            }
            
            return result;
        },


        navStart()
        {
            let range = (vm.navRadius * 2) + 1,
                result = vm.pageID - vm.navRadius;
            
            if (result < 1)
            {
                result = 1;
            }
            else if (result > vm.pageCount - range)
            {
                result = Math.max(vm.pageCount - range + 1, 1);
            }
            
            return result;
        },


        pageCount()
        {
            return Math.ceil(vm.user.orders.length / vm.perPage);
        },


        start()
        {
            return (vm.pageID - 1) * vm.perPage;
        },
    },


    methods: {
        getOrderTotal(o)
        {
            let t = 0;

            for (let i = 0; i < o.items.length; i++)
            {
                let oi = o.items[i];

                // Multiply the quantity by the price * 100 to convert from euros to cents
                t += parseInt(oi.quantity) * parseFloat(oi.price) * 100;
            }

            return vm.asMoney(t);
        },


        next()
        {
            if (vm.pageID < vm.pageCount)
            {
                vm.pageID++;
            }
        },


        prev()
        {
            if (vm.pageID > 1)
            {
                vm.pageID--;
            }
        },
    },

    
    setup()
	{
		const { asMoney, formatDate, getOrderStatus, getTranslation } = useFunctions();


		return {
            asMoney,
            formatDate,
            getOrderStatus,
            getTranslation,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>