import { createRouter, createWebHistory } from "vue-router";

import Account from "@/views/MyAccountPage.vue";
import Cart from "@/views/CartPage.vue";
import Checkout from "@/views/CheckoutPage.vue";
import Contact from "@/views/ContactPage.vue";
import Custom from "@/views/CustomizationPage.vue";
import DefaultPage from "@/views/DefaultPage.vue";
import Home from "@/views/HomePage.vue";
// import Landing from "@/views/LandingPage.vue";
import PresetPage from "@/views/PresetPage.vue";
import PresetsOverview from "@/views/PresetsOverview.vue";
import PrintPage from "@/views/PrintPage.vue";
import PrintsOverview from "@/views/PrintsOverview.vue";
import ProductPage from "@/views/ProductPage.vue";
import ProductsOverview from "@/views/ProductsOverview.vue";
import SetsOverview from "@/views/SetsOverview.vue";
import SetPage from "@/views/SetPage.vue";

const routes = [
    // {
    //     path: "/",
    //     name: "LandingPage",
    //     component: Landing,
    // },

    {
        // path: "/:lang",
        path: "/",
        name: "HomePage",
        component: Home,
    },

    {
        path: "/account",
        name: "MyAccount",
        component: Account,
    },

    {
        path: "/account/:alias",
        name: "MyAccountTab",
        component: Account,
    },

        {
            path: "/account/order/:id",
            name: "ViewOrder",
            component: Account,
        },
	
    {
        path: "/art-references",
        name: "ArtReferencesEN",
        component: Custom,
    },
	
        // {
        //     path: "/referentie-fotos",
        //     name: "ArtReferencesNL",
        //     component: Custom,
        // },
	
    {
        path: "/basket",
        name: "CartEN",
        component: Cart,
    },
	
        // {
        //     path: "/winkelmand",
        //     name: "CartNL",
        //     component: Cart,
        // },
	
    {
        path: "/checkout",
        name: "OrderEN",
        component: Checkout,
    },
	
        // {
        //     path: "/bestellen",
        //     name: "OrderNL",
        //     component: Checkout,
        // },

	{
		path: "/contact",
		name: "ContactPage",
		component: Contact,
	},
	
    {
        path: "/customization",
        name: "CustomizationEN",
        component: Custom,
    },
	
        // {
        //     path: "/maatwerk",
        //     name: "CustomizationNL",
        //     component: Custom,
        // },
	
    {
        path: "/photo-gifts",
        name: "ProductsOverviewEN",
        component: ProductsOverview,
    },
    
        // {
        //     path: "/fotoproducten",
        //     name: "ProductsOverviewNL",
        //     component: ProductsOverview,
        // },
	
    {
        path: "/photo-gifts/:alias",
        name: "ProductPageEN",
        component: ProductPage,
    },

        // {
        //     path: "/fotoproducten/:alias",
        //     name: "ProductPageNL",
        //     component: ProductPage,
        // },

    {
        path: "/presets",
        name: "PresetsOverview",
        component: PresetsOverview,
    },

    {
        path: "/presets/:alias",
        name: "PresetPage",
        component: PresetPage,
    },

    {
        path: "/prints",
        name: "PrintsOverview",
        component: PrintsOverview,
    },

    {
        path: "/prints/:alias",
        name: "PrintPage",
        component: PrintPage,
    },

    {
        path: "/sets",
        name: "SetsOverview",
        component: SetsOverview,
    },

    {
        path: "/sets/:alias",
        name: "SetPage",
        component: SetPage,
    },
	
    {
        path: "/:alias",
        name: "DefaultPage",
        component: DefaultPage,
    },
];

const router = createRouter({
    scrollBehavior (to, from, savedPosition) {
        return savedPosition ? savedPosition : { top: 0 };
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
