<template>
    <form :class="{ 'signup': loginTab === 1 }">

        <transition name="fade" mode="out-in">

            <loader-element v-if="processing" />

        </transition>

        
        <slot></slot>


        <ul v-if="feedback === null || feedbackClass !== '' ">
            
            <li v-if="feedback !== null" :class="feedbackClass" v-html="feedback" />

            <li>
                <input type="email" name="email" v-model="user.email" :placeholder="getTranslation('placeholder_email')" :class="isRegister ? 'validate' : ''" />

                <font-awesome-icon v-if="isRegister" :icon="validEmail ? 'fa-solid fa-check' : 'fa-solid fa-times'" />
            </li>

            <li v-if="!isReset">
                <input :type="pwType" name="password" v-model="user.password" :placeholder="getTranslation('placeholder_password')" :class="isRegister ? 'validate' : 'password'" />

                <!-- <i v-if="isLogin" class="fas" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'" @click="showPassword = !showPassword"></i>
                <i v-else class="fas" :class="validPassword ? 'fa-check' : 'fa-times'"></i> -->
                <font-awesome-icon v-if="isLogin" :icon="showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'" @click="showPassword = !showPassword" />
                <font-awesome-icon v-else :icon="validPassword ? 'fa-solid fa-check' : 'fa-solid fa-times'" />
            </li>

            <li v-if="isRegister">
                <input :type="pw2Type" name="password_check" class="password" v-model="passwordCheck" :placeholder="getTranslation('placeholder_password_check')" />

                <font-awesome-icon :icon="validPassword && passwordCheck == user.password ? 'fa-solid fa-check' : 'fa-solid fa-times'" />
            </li>

            <li v-if="isRegister" class="note" v-html="getTranslation('password_criteria')" />

            <li class="buttons">
                <a v-if="isLogin" href="#" class="button" :class="btnState" @click.prevent="userAction('login')">
                    {{ getTranslation('btn_login') }}
                </a>

                <a href="#" v-else-if="isRegister" class="button" :class="btnState" @click.prevent="userAction('register')">
                    {{ getTranslation('btn_register') }}
                </a>

                <a v-else-if="isReset" href="#" class="button" :class="btnState" @click.prevent="userAction('passwordReset')">
                    {{ getTranslation('btn_reset_pw') }}
                </a>
            </li>
            
            <li v-if="!isLogin" class="toggle">
                <span v-if="togglePreText" v-html="getTranslation('pre_register')" />
                
                <a href="#" @click.prevent="toggle(0)" v-html="getTranslation('btn_toggle_to_login')" />
            </li>
            
            <li v-if="!isRegister" class="toggle">
                <span v-if="togglePreText" v-html="getTranslation('pre_login')" />

                <a href="#" @click.prevent="toggle(1)" v-html="getTranslation('btn_toggle_to_register')" />
            </li>

            <li v-if="!isReset" class="toggle">
                <a href="#" @click.prevent="toggle(2)" v-html="getTranslation('btn_toggle_to_reset_pw')" />
            </li>
        </ul>

        <ul v-else>
            <li :class="feedbackClass" v-html="feedback" />
            
            <li v-if="!isLogin" class="toggle">
                <span v-if="togglePreText" v-html="getTranslation('pre_register')" />
                
                <a href="#" @click.prevent="toggle(0)" v-html="getTranslation('btn_toggle_to_login')" />
            </li>

            <li v-if="!isRegister" class="toggle">
                <span v-if="togglePreText" v-html="getTranslation('pre_login')" />
            
                <a href="#" @click.prevent="toggle(1)" v-html="getTranslation('btn_toggle_to_register')" />
            </li>
        </ul>

    </form>
</template>


<script>
import axios from 'axios';

import { mapActions, mapGetters } from 'vuex';

import LoaderElement from '@/components/LoaderElement.vue';
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    components: {
        LoaderElement,
    },


    props: {
        loginTab: {
            type: Number,
            default: 0,
        },
        togglePreText: {
            type: Boolean,
            default: false,
        },
    },


    data()
    {
        return {
            feedback: null,
            feedbackClass: '',
            passwordCheck: '',
            processed: false,
            showPassword: false,
            showPasswordCheck: false,
			submitted: false,
			success: null,
            user: {
                email: '',
                password: '',
                // language: this.$route.params.lang,
                language: 'en',
            },
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'isDev',
        ]),


        btnState()
        {
            let v = true;

            if (!vm.validForm || vm.processing) v = false;

            return v ? '' : 'disabled';
        },


        isLogin()
        {
            return vm.loginTab === 0;
        },


        isRegister()
        {
            return vm.loginTab === 1;
        },


        isReset()
        {
            return vm.loginTab === 2;
        },


		processing()
		{
			return vm.processed !== vm.submitted;
		},


        pwType()
        {
            return vm.showPassword ? 'text' : 'password';
        },


        pw2Type()
        {
            return vm.showPasswordCheck ? 'text' : 'password';
        },


        validEmail()
        {
            return vm.isValidEmail(vm.user.email);
        },


        validForm()
        {
            if (vm.user.email.length == 0) return false;

            if (!vm.isReset && (vm.user.password.length == 0 || !vm.isValidEmail(vm.user.email))) return false;

            if (vm.isRegister && (vm.user.password != vm.passwordCheck || !vm.isValidPassword(vm.user.password))) return false;

            return true;
        },


        validPassword()
        {
            return vm.isValidPassword(vm.user.password);
        },
    },


    methods: {
        ...mapActions([
            'updateUser',
        ]),


        async userAction(action)
		{
			vm.submitted = true;
            
            let formData = {
                action: action,
                data: JSON.stringify(vm.user),
            };

			if (vm.isDev)
			{
				setTimeout(() => {
                    vm.updateUser({
                        id: 1,
                        name: 'Pascal Maranus',
                        email: vm.user.email,
                        addresses: [
                            {
                                id: 1,
                                address: 'J.W. Schuurmanstraat 123',
                                address_2: null,
                                postcode: '4357 EJ',
                                city: 'Domburg',
                                country: 'NL',
                                deleted: 0,
                                score: 0,
                            },
                            {
                                id: 2,
                                address: 'Agathastraat 47',
                                address_2: null,
                                postcode: '4363BC',
                                city: 'Aagtekerke',
                                country: 'NL',
                                deleted: 0,
                                score: 1,
                            },
                            {
                                id: 3,
                                address: 'Wijgaardstraat 10B',
                                address_2: null,
                                postcode: '4357 AR',
                                city: 'Domburg',
                                country: 'NL',
                                deleted: 1,
                                score: 0,
                            },
                        ],
                        orders: [
                            {
                                id: 4,
                                order_name: 'Pascal Maranus',
                                order_address: 2,
                                order_date: '2022-06-05',
                                shipping_name: 'P. Maranus',
                                shipping_address: 1,
                                fk_order_status: 1,
                                shipping_date: '',
                                order_confirmation: 1,
                                items: [
                                    {
                                        fk_order: 4,
                                        product: 91,
                                        variant: 104,
                                        quantity: 1,
                                        price: 45.95,
                                        vat: 21,
                                        digital: 0,
                                    },
                                    {
                                        fk_order: 4,
                                        product: 123,
                                        variant: 0,
                                        quantity: 2,
                                        price: 19.95,
                                        vat: 21,
                                        digital: 0,
                                    },
                                    {
                                        fk_order: 4,
                                        product: 196,
                                        variant: 0,
                                        quantity: 1,
                                        price: 12.50,
                                        vat: 21,
                                        digital: 0,
                                    },
                                    {
                                        fk_order: 4,
                                        product: 252,
                                        variant: 0,
                                        quantity: 1,
                                        price: 8.50,
                                        vat: 21,
                                        digital: 1,
                                    },
                                ],
                            },
                            {
                                id: 3,
                                order_name: 'Pascal Maranus',
                                order_address: 2,
                                order_date: '2022-05-04',
                                shipping_name: 'P. Maranus',
                                shipping_address: 1,
                                fk_order_status: 3,
                                shipping_date: '2020-05-15',
                                order_confirmation: 1,
                                items: [],
                            },
                            {
                                id: 2,
                                order_name: 'Pascal Maranus',
                                order_address: 1,
                                order_date: '2021-06-04',
                                shipping_name: 'Pascal Maranus',
                                shipping_address: 1,
                                fk_order_status: 4,
                                shipping_date: '2021-06-05',
                                order_confirmation: 1,
                                items: [],
                            },
                            {
                                id: 1,
                                order_name: 'Pascal Maranus',
                                order_address: 1,
                                order_date: '2020-05-04',
                                shipping_name: 'P. Maranus',
                                shipping_address: 2,
                                fk_order_status: 5,
                                shipping_date: '2020-05-15',
                                order_confirmation: 1,
                                items: [],
                            },
                        ],
                    })
                    .then(() => {
                        vm.success = true;
                        vm.processed = true;
                    });
				}, 1000);
			}
			else
			{
				await axios.post(vm.base + 'api/forms/', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				})
				.then(response => {
                    vm.updateUser(response.data.success.user);
					vm.processed = true;
					vm.success = response.data.success.success;
                    // Testtest12

                    if (action === 'passwordReset')
                    {
                        let fb = response.data.success.success === true ? 'new_password_sent' : 'new_password_error';
                        vm.feedback = vm.getTranslation(fb);
                    }
                    else if (action === 'register')
                    {
                        let fb = response.data.success.success === false ? response.data.success.error : null;
                        
                        if (fb)
                        {
                            vm.feedbackClass = 'feedback warning';
                            vm.feedback = vm.getTranslation(fb);
                        }
                    }
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},


        toggle(tab)
        {
            setTimeout(() => {
                vm.$emit('toggle', tab)
            }, 13)
        },
    },

    
    setup()
	{
		const { getTranslation, isValidEmail, isValidPassword } = useFunctions();


		return {
            getTranslation,
            isValidEmail,
            isValidPassword,
		};
	},


    created()
    {
        vm = this;

        if (vm.isDev)
        {
            vm.user.email = 'pascal@dizero.nl';
            vm.user.password = 'DiZer0Prod$';
        }
    },


    watch:
    {
        loginTab()
        {
            vm.feedback = null;
        },
    }
}
</script>