<template>

    <footer>

		<nav>

            <router-link v-for="(item, id) in nav" :key="['item', id].join('-')" :to="to(item, language)">
                {{ item.name }}
            </router-link>

        </nav>

    </footer>


	<div class="credits">
		&copy; {{ year }} {{ officialName }}
	</div>

</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
	data()
	{
		return {
			year: new Date().getFullYear(),
		};
	},


	computed: {
		...mapGetters([
			'siteName',
			'structure',
		]),


        // language()
        // {
        //     return vm.$route.params.lang;
        // },


		nav()
        {
            let nav = [];

            // for (let i = 0; i < vm.structure[vm.language].length; i++)
            for (let i = 0; i < vm.structure.length; i++)
            {
                // let item = vm.structure[vm.language][i];
                let item = vm.structure[i];

                if (item.isFooterLink)
                {
                    nav.push(item);
                }
            }

            return nav;
        },


        officialName()
        {
            return vm.siteName + ' Photography';
        },
	},


	setup()
	{
		const { to } = useFunctions();

		return {
			to,
		};
	},


	created()
	{
		vm = this;
	},
}
</script>