<template>
    <div class="product-meta">
        <h2>    
            {{ product.pagetitle }}
        </h2>
        
        <div class="meta" v-if="(product.variables.hasOwnProperty('categories') && product.variables.categories.split('||').indexOf('147') >= 0)">
            <font-awesome-icon icon="fa-sharp fa-solid fa-bookmark" />
        
            {{ getTranslation('meta_private_collection') }}
        </div>
        
        <div class="meta" v-if="product.variables.hasOwnProperty('itemNumber')">
            <font-awesome-icon icon="fa-sharp fa-solid fa-hashtag" />

            {{ product.variables.itemNumber }}
        </div>
        
        <div class="meta" v-if="product.variables.hasOwnProperty('location') && true == false">
            <font-awesome-icon icon="fa-sharp fa-solid fa-map-marker-alt" />

            {{ location }}
        </div>

        <div class="meta" v-if="product.variables.hasOwnProperty('imgRatio') && true == false">
            <font-awesome-icon icon="fa-sharp fa-regular fa-crop-alt" />

            {{ product.variables.imgRatio.replace('x', ':') }}
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        product: Object,
    },


    computed: {
        ...mapGetters([
            'locations',
        ]),


        location()
        {
            let l = vm.getListItemById(vm.product.variables.location, vm.locations);
            
            return l.pagetitle;
        },
    },


	setup()
	{
        const { getListItemById, getTranslation } = useFunctions();

		return {
            getListItemById,
            getTranslation,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>