<template>
    <div class="loader">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="841.9px" height="595.3px" viewBox="0 0 841.9 595.3" style="enable-background:new 0 0 841.9 595.3;" xml:space="preserve">
        
        <g id="signature">
            <path id="m" class="drawn" d="M235.5,412.7c0,0,179.6-71.2,279.3-207.4c0,0-84.2,157.4-45.9,134.9C517,311.9,604.7,218,625.7,173.5
            L504,436.1"/>
            <path id="p" class="drawn" d="M337.8,173.5L247.1,384c0,0,31.5-88.6-90.1-59.5s-123.3,27.7-83.9-0.3
            c43.1-30.6,199.2-110.7,279.8-139.4c67-23.9,174.3-42.1,130.6,14.5c-38,49.1-134.8,85.2-157.4,79"/>
        </g>
        </svg>
        
    </div>
</template>


<script>
import gsap from 'gsap'
import DrawSVGPlugin from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(DrawSVGPlugin);

var vm;

export default {
    methods: {
        setAnimated()
        {
            vm.$emit('animated')
        },
    },


    created()
    {
        vm = this;
    },


    mounted()
    {
        let m = document.querySelector('#m'),
            p = document.querySelector('#p'),
            tl = gsap.timeline();
        
        tl.from(p, {
                duration: .78,
                drawSVG: 0,
            },
            1,
        )
        .from(m, {
                duration: .52,
                drawSVG: 0,
            },
            ">",
        )
        // Tell you're done animating
        .eventCallback('onComplete', vm.setAnimated);
    },
}
</script>


<style type="text/css" scoped>
.drawn {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 6;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}
</style>