<template>

    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main class="portfolio">
                <section>
                    <div>
                        <h1 v-html="page.pagetitle" />
                        <h2 v-if="page.longtitle.length > 0" v-html="page.longtitle" />
        
                        <div class="content" v-if="page.content.length > 0" v-html="page.content" />
                    </div>
                </section>
        
        
                <section class="products gallery">
        
                    <aside class="filters">
                        <a href="#" class="filter-toggle" @click.prevent="showFilters = !showFilters">
                            <font-awesome-icon icon="fa-solid fa-filter" />
        
                            <span>
                                {{ getTranslation('filters') }}
                            </span>
                        </a>
        
                        <span>
                            <font-awesome-icon icon="fa-solid fa-filter" />
        
                            <span>
                                {{ getTranslation('filters') }}
                            </span>
                        </span>
        
                        <div class="filter-options" :class="showFilters ? 'show' : 'hide'">
        
                            <div class="filter-option" v-if="appliedFilters > 0">
                                <ul>
                                    <li>
                                        <a href="#" class="reset" @click.prevent="resetFilters">
                                            <font-awesome-icon icon="fa-solid fa-times" />
        
                                            <label>
                                                <span v-html="getTranslation('label_reset_filters')" />
                                            </label>
                                        </a>
                                    </li>
                                </ul>
                            </div>
        
        
                            <div class="filter-option">
                                <h3>
                                    {{ getTranslation('filter_sort') }}
                                </h3>
        
                                <ul>
                                    <li class="no-checkbox">
                                        <select-box :items="orderOptions" :prefix="'order'" :selected="order"
                                            @select="setOrder" />
                                    </li>
                                </ul>
                            </div>
        
        
                            <div class="filter-option" v-if="true == false">
                                <h3>
                                    {{ getTranslation('filter_categories') }}
                                </h3>
        
                                <ul>
                                    <li v-for="(c, id) in categories" :key="['cat', appliedFilters, id].join('-')"
                                        :class="!showFilter('categories', c.id) ? 'disabled' : ''">
                                        <input v-if="filterApplyCount('categories', c.id) > 0" type="checkbox" :value="c.id"
                                            :id="['cat', c.alias].join('-')" v-model="filters.categories" />
        
                                        <font-awesome-icon
                                            :icon="filters.categories.indexOf(c) >= 0 ? 'fa-solid fa-square' : 'fa-regular fa-square'"
                                            @click="clickLabel" />
        
                                        <label :for="['cat', c.alias].join('-')" tabindex="1">
                                            {{ getTranslation(['cat', c.alias].join('_')) }}
        
                                            ({{ filterApplyCount('categories', c.id) }})
                                        </label>
                                    </li>
                                </ul>
                            </div>
        
        
                            <div class="filter-option" v-if="typeFilters.length > 1">
                                <h3>
                                    {{ getTranslation('filter_product_type') }}
                                </h3>
        
                                <ul>
                                    <li v-for="(t, id) in typeFilters" :key="['type', appliedFilters, id].join('-')"
                                        :class="!showFilter('productType', t.value) ? 'disabled' : ''">
                                        <input type="checkbox" :value="t.value" :id="['type', t.value].join('-')"
                                            v-model="filters.productType" />
        
                                        <font-awesome-icon
                                            :icon="filters.productType.indexOf(t.value) >= 0 ? 'fa-solid fa-square' : 'fa-regular fa-square'"
                                            @click="clickLabel" />
        
                                        <label :for="['type', t.value].join('-')">
                                            {{ t.text }}
        
                                            ({{ filterApplyCount('productType', t.value) }})
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </aside>
        
        
                    <div class="items" :key="$route.name">
        
                        <transition-group name="pop-in">
                            <product-preview v-for="(pr, id) in products" :key="['product', pr.alias].join('-')" :index="id"
                                :photo="pr" />
                        </transition-group>
        
                        <font-awesome-icon v-if="products.length === 0" icon="fa-sharp fa-regular fa-spinner-third" spin
                            size="2xl" />
        
                    </div>
        
                </section>
        
            </main>


            <slot name="footer" />
        </div>
    </div>

</template>


<script>
import { mapGetters } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import useFunctions from '@/hooks/functions.js';

import ProductPreview from '@/components/ProductPreview.vue';
import SelectBox from '@/components/SelectBox.vue';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        ProductPreview,
        SelectBox,
    },


    data()
    {
        return {
            allProducts: [],
            filters: {
                'categories': [],
                'productType': [],
            },
            order: 'newest',
            orderOptions: [
                'newest',
                'a-z',
                'z-a',
                'price-asc',
                'price-desc',
            ],
            quantity: 1,
            showFilters: false,
            showModal: this.$route.meta.showModal,
            smoother: null,
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'cart',
            'categories',
            'currency',
            'duration',
        ]),


        appliedFilters()
        {
            let af = 0,
                keys = Object.keys(vm.filters);

            for (let i = 0; i < keys.length; i++)
            {
                let k = keys[i],
                    f = vm.filters[k];

                af += f.length;
            }

            return af;
        },


        products()
        {
            let array = vm.allProducts,
                filtered = [],
                filters = Object.keys(vm.filters);
            
            
            // Start by looping through the products
            for (let i = 0; i < array.length; i++)
            {
                let allApply = true,
                    p = array[i],
                    fKey = p.publishedon;
                    

                switch(vm.order)
                {
                    case 'a-z':
                    case 'z-a':
                        fKey = p.alias;
                        break;
                    
                    case 'price-asc':
                    case 'price-desc':
                        // Because multiple items can have the same price, append the ID to keep the keys unique
                        fKey = p.variables.price.toString().padStart(13, '0') + '-' + p.id;
                        break;
                }


                // Loop through all filters
                for (let j = 0; j < filters.length; j++)
                {
                    let f = filters[j];
                    
                    // Check if all filters apply
                    for (let k = 0; k < vm.filters[f].length; k++)
                    {
                        let v = vm.filters[f][k];

                        if (!vm.filterApplies(p, f, v))
                        {
                            allApply = false;
                        }
                    }
                }


                // If all filters apply, add the product to the filtered list
                if (allApply)
                {
                    // filtered.push(p);
                    filtered[fKey] = p;
                }
            }



            // Arrange the keys in alphabetical order (or reversed where needed)
            let keys = Object.keys(filtered).sort(),
                ordered = [];

            if (['newest', 'price-desc', 'z-a'].indexOf(vm.order) >= 0) keys = keys.reverse();

            for (let i = 0; i < keys.length; i++)
            {
                ordered.push(filtered[keys[i]])
            }


            return ordered;
        },


        typeFilters()
        {
            let tf = [];

            for (let i = 0; i < vm.allProducts.length; i++)
            {
                let p = vm.allProducts[i],
                    t = p.variables.productType,
                    tExists = false;
                
                // Check if the type is already listed or not
                for (let j = 0; j < tf.length; j++)
                {
                    if (tf[j].value == t)
                    {
                        tExists = true;
                        break;
                    }
                }
                
                // If the type doesn't exist yet, add it
                if (!tExists)
                {
                    tf.push({
                        text: vm.getTranslation(['type', t].join('_')),
                        value: t,
                    });
                }
            }

            tf.sort(function (a, b) {
                let valA = a.value.toString().toLowerCase(),
                    valB = b.value.toString().toLowerCase();
                
                if (valA < valB)
                {
                    return -1;
                }
                else if (valB < valA)
                {
                    return 1;
                }
                else
                {
                    return 0;
                }
            });

            return tf;
        },
    },


    methods: {
        clickLabel({target})
        {
            target.nextSibling.click();
        },


        filterApplies(product, field, value)
        {
            let v = product.variables[field];

            
            // Convert to an array for potential multi-selects
            let tv = v.split('||');


            // If the filter does not apply, return false
            return tv.indexOf(value) >= 0;
        },


        filterApplyCount(field, value)
        {
            let c = 0;

            // Loop through all remaining products
            for (let i = 0; i < vm.products.length; i++)
            {
                if (vm.filterApplies(vm.products[i], field, value)) c++;
            }

            return c;
        },


        image(s)
        {
            return Object.prototype.hasOwnProperty.call(s.variables, 'image') ? "background-image: url('" + vm.base + s.variables.image + "')" : '';
        },


        resetFilters()
        {
            let f = vm.filters,
                keys = Object.keys(f);

            for (let i = 0; i < keys.length; i++)
            {
                f[keys[i]] = [];
            }

            vm.filters = f;
        },


        setOrder(o)
        {
            vm.order = o;
        },


        showFilter(field, value)
        {
            if (vm.filterApplyCount(field, value) == 0) return false;
            if (vm.filterApplyCount(field, value) == vm.allProducts.length) return false;

            return true;
        },
    },

    
    setup()
	{
		const { getTranslation } = useFunctions();


		return {
            getTranslation,
		};
	},


    created()
    {
        vm = this;
        vm.allProducts = vm.page.sections;
    },


    mounted()
    {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })
    },
}
</script>