<template>
    <div id="smooth-wrapper">
        <div id="smooth-content">
            <main>
        
                <transition name="fade" mode="out-in">
        
                    <loader-element v-if="processing" />
        
                </transition>
        
        
                <section class="cart">
                    <h1>
                        {{ page.pagetitle }}
                    </h1>
        
                    <h2>
                        {{ page.longtitle }}
                    </h2>
        
                    <div class="container order">
                        <div class="order-step">
                            <h3 v-if="!user && loginTab === 0">
                                {{ getTranslation('login') }}
                            </h3>
        
                            <h3 v-else-if="!user">
                                {{ getTranslation('register') }}
                            </h3>
        
                            <div class="tab" v-if="!user">
        
                                <div class="small" v-if="loginTab === 1" v-html="page.content" />
        
        
                                <login-form :loginTab="loginTab" :togglePreText="true" @toggle="setLoginTab" />
        
                            </div>
        
        
                            <h3>
                                {{ getTranslation('order_billing_title') }}
                            </h3>
        
                            <div class="tab">
        
                                <div class="address-previews" v-if="user">
        
                                    <AddressPreview v-if="mainAddress.deleted == 0" class="main"
                                        :class="selectedBillingAddress == mainAddress.id ? 'selected' : ''"
                                        :address="mainAddress" @click="selectAddress(mainAddress.id)" />
        
        
                                    <AddressPreview v-for="(a, id) in otherAddresses" :key="['other', id].join('-')"
                                        :class="selectedBillingAddress == a.id ? 'selected' : ''" :address="a"
                                        @click="selectAddress(a.id)" />
        
                                </div>
        
        
                                <form>
                                    <ul>
                                        <li>
                                            <input type="text" name="billing_name" v-model="formBilling.name"
                                                :placeholder="getTranslation('placeholder_name')" />
                                        </li>
                                        <li>
                                            <input type="text" name="billing_address" v-model="formBilling.address"
                                                :placeholder="getTranslation('placeholder_address')" />
                                        </li>
                                        <li>
                                            <input type="text" name="billing_address_2" v-model="formBilling.address_2"
                                                :placeholder="getTranslation('placeholder_address_2')" />
                                        </li>
                                        <li>
                                            <input type="text" name="billing_postcode" v-model="formBilling.postcode"
                                                :placeholder="getTranslation('placeholder_postcode')" />
                                        </li>
                                        <li>
                                            <input type="text" name="billing_city" v-model="formBilling.city"
                                                :placeholder="getTranslation('placeholder_city')" />
                                        </li>
                                        <li>
                                            <CountryPicker :items="countriesBilling" :open="openBilling"
                                                :value="formBilling.country" @select="setBillingCountry">
        
                                                <input v-model="filterBilling" :placeholder="placeholderBilling"
                                                    @focus="openBilling = true" />
        
                                            </CountryPicker>
                                        </li>
                                    </ul>
                                </form>
        
                            </div>
                        </div>
        
        
                        <div class="order-step">
                            <h3>
                                {{ getTranslation('order_shipping_title') }}
                            </h3>
        
                            <div class="tab transparent">
                                <font-awesome-icon class="checkbox"
                                    :icon="sameShipping ? 'fa-solid fa-square' : 'fa-regular fa-square'"
                                    @click="sameShipping = !sameShipping" />
        
                                <small @click="sameShipping = !sameShipping">
                                    {{ getTranslation('label_same_shipping') }}
                                </small>
                            </div>
        
                            <div class="tab" :class="sameShipping ? 'hidden' : ''">
        
                                <div class="address-previews" v-if="user">
        
                                    <AddressPreview v-for="(a, id) in altAddresses"
                                        :key="['alt', selectedBillingAddress, id].join('-')"
                                        :class="selectedBillingAddress == a.id ? 'selected' : ''" :address="a"
                                        @click="selectAddress(a.id, 'shipping')" />
        
                                </div>
        
        
                                <form>
                                    <ul>
                                        <li>
                                            <input type="text" v-model="formShipping.name"
                                                :placeholder="getTranslation('placeholder_name')" />
                                        </li>
        
                                        <li>
                                            <input type="text" v-model="formShipping.address"
                                                :placeholder="getTranslation('placeholder_address')" />
                                        </li>
                                        <li>
                                            <input type="text" v-model="formShipping.address_2"
                                                :placeholder="getTranslation('placeholder_address_2')" />
                                        </li>
                                        <li>
                                            <input type="text" v-model="formShipping.postcode"
                                                :placeholder="getTranslation('placeholder_postcode')" />
                                        </li>
                                        <li>
                                            <input type="text" v-model="formShipping.city"
                                                :placeholder="getTranslation('placeholder_city')" />
                                        </li>
                                        <li>
                                            <CountryPicker :items="countriesShipping" :open="openShipping"
                                                :value="formShipping.country" @select="setShippingCountry">
        
                                                <input v-model="filterShipping" :placeholder="placeholderShipping"
                                                    @focus="openShipping = true" />
        
                                            </CountryPicker>
                                        </li>
                                    </ul>
                                </form>
        
                            </div>
        
                        </div>
        
        
        
                        <div v-if="canPickPaymentMethod" class="order-step">
                            <h3>
                                {{ getTranslation('order_payment_method') }}
                            </h3>
        
                            <div class="tab">
                                <ul class="select-list">
                                    <li class="payment-method" v-for="(pm, id) in paymentMethods" :key="['pm', id].join('-')"
                                        :class="pm.alias == paymentMethod ? 'selected' : ''">
                                        <a href="#" @click.prevent="setPaymentMethod(pm.alias)">
                                            <font-awesome-icon icon="fa-solid fa-circle" />
        
                                            <span>
                                                {{ pm.pagetitle }}
                                            </span>
        
                                            <span class="logo">
                                                <img :src="getImgUrl(pm)"
                                                    :alt="pm.alias == 'creditcard' ? 'Mastercard' : pm.pagetitle" />
        
                                                <img :src="getImgUrl({ variables: { image: pm.variables.image.replace('mastercard', 'visa') }})"
                                                    v-if="pm.alias == 'creditcard'" alt="VISA" />
                                            </span>
        
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
        
        
                        <div class="order-step">
                            <h3>
                                {{ getTranslation('order_check') }}
                            </h3>
        
                            <div class="tab">
                                <ul class="cart-items compact">
                                    <li v-for="(ci, id) in cart" :key="id" :style="getCartItemSettings(ci)">
                                        <div class="img">
                                            <figure class="size-1x1" />
                                        </div>
        
                                        <strong class="name" v-html="getCartItemName(ci)" />
        
                                        <strong class="quantity"></strong>
        
                                        <strong class="price">
                                            {{ currency }}
                                        </strong>
                                    </li>
                                </ul>
        
                                <div class="totals">
                                    <span>
                                        {{ getTranslation('subtotal') }}:
                                    </span>
        
                                    <span>
                                        {{ asMoney(cartInfo.total) }}
                                        {{ currency }}
                                    </span>
                                </div>
        
                                <div class="totals" v-if="discount !== null">
                                    <span>
                                        {{ getTranslation('discount') }}:
                                    </span>
        
                                    <span>
                                        {{ asMoney(discountTotal) }}
                                        {{ currency }}
                                    </span>
                                </div>
        
                                <div class="totals">
                                    <span>
                                        {{ getTranslation('shipping_costs') }}:
                                    </span>
        
                                    <span>
                                        {{ asMoney(shippingCosts) }}
                                        {{ currency }}
                                    </span>
                                </div>
        
                                <div class="totals">
                                    <strong>
                                        {{ getTranslation('total') }}:
                                    </strong>
        
                                    <strong>
                                        {{ asMoney(orderTotalAfterShipping) }}
                                        {{ currency }}
                                    </strong>
                                </div>
        
                            </div>
        
        
                            <div class="tab" v-if="discount === null">
                                <discount />
                            </div>
        
        
                            <div class="tab transparent">
                                <font-awesome-icon class="checkbox"
                                    :icon="termsAccepted ? 'fa-solid fa-square' : 'fa-regular fa-square'"
                                    @click="termsAccepted = !termsAccepted" />
        
                                <small v-html="termsLink" @click.prevent="clickHandler" />
                            </div>
        
        
                            <a href="#" class="button add-to-cart" :class="validOrder ? '' : 'disabled'"
                                @click.prevent="toPayment">
                                <font-awesome-icon icon="fa-solid fa-angle-right" />
        
                                {{ getTranslation('pay_order') }}
                            </a>
        
                        </div>
        
                    </div>
                </section>
            </main>



            <slot name="footer" />
        </div>
    </div>
</template>


<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import AddressPreview from '@/components/AddressPreview.vue';
import CountryPicker from '@/components/CountryPicker.vue';
import Discount from '@/components/DiscountForm.vue';
import LoaderElement from '@/components/LoaderElement.vue';
import LoginForm from '@/components/LoginForm.vue';
import useCart from '@/hooks/cart.js';
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        AddressPreview,
        CountryPicker,
        Discount,
        LoaderElement,
        LoginForm,
    },


    data()
    {
        return {
            canPickPaymentMethod: false,
            filterBilling: '',
            filterShipping: '',
            formBilling: {
                name: '',
                address: '',
                address_2: '',
                postcode: '',
                city: '',
                country: 'NL',
            },
            formShipping: {
                name: '',
                address: '',
                address_2: '',
                postcode: '',
                city: '',
                country: 'NL',
            },
            loginTab: 1,
            newUser: {
                name: '',
                email: '',
                password: '',
            },
            openBilling: false,
            openShipping: false,
            // paymentMethod: 'ideal',
            placeholderBilling: '',
            placeholderShipping: '',
            processing: false,
            sameShipping: true,
            selectedBillingAddress: 0,
            showPassword: false,
            smoother: null,
            termsAccepted: false,
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'cart',
            'countries',
            'currency',
            'discount',
            'isDev',
            // 'paymentMethods',
            'shippingRanges',
            'user',
        ]),


        altAddresses()
        {
            let aa = [];

            if (vm.user)
            {
                for (let i = 0; i < vm.user.addresses.length; i++)
                {
                    let a = vm.user.addresses[i];

                    if (a.id != vm.selectedBillingAddress && a.deleted == 0) aa.push(a);
                }
            }

            return aa;
        },


        countriesBilling()
        {
            return vm.filterCountries(vm.filterBilling);
        },


        countriesShipping()
        {
            return vm.filterCountries(vm.filterShipping);
        },


        mainAddress()
        {
            if (vm.user)
            {
                let ma = null;

                for (let i = 0; i < vm.user.addresses.length; i++)
                {
                    let a = vm.user.addresses[i];

                    if (ma === null || a.score > ma.score) ma = a;
                }

                if (ma !== null)
                {
                    return ma;
                }
            }


            return {
                name: '',
                address: '',
                address_2: '',
                postcode: '',
                city: '',
                country: '',
                score: 0,
            };
        },


        onlyDigitals()
        {
            for (let i = 0; i < vm.cart.length; i++)
            {
                if (vm.cart[i].digital != 1) return false;
            }

            return true;
        },


        orderTotalAfterShipping()
        {
            return vm.orderTotal + vm.shippingCosts;
        },


        otherAddresses()
        {
            let oa = [];

            if (vm.user)
            {
                for (let i = 0; i < vm.user.addresses.length; i++)
                {
                    let a = vm.user.addresses[i];

                    if (a != vm.mainAddress && a.deleted == 0) oa.push(a);
                }
            }

            return oa;
        },


        pwType()
        {
            return vm.showPassword ? 'text' : 'password';
        },


        shippingCosts()
        {
            if (vm.onlyDigitals) return 0;

            let country = null,
                items = vm.countries.en;
                // items = vm.countries[vm.$route.params.lang];

            for (let i = 0; i < items.length; i++)
            {
                let c = items[i];
                
                if (c.iso == vm.formShipping.country)
                {
                    country = c;
                }
            }

            if (country)
            {
                for (let j = 0; j < vm.shippingRanges.length; j++)
                {
                    let sr = vm.shippingRanges[j];

                    if (sr.id == country.fk_shipping_range)
                    {
                        return parseFloat(sr.cost) * 100;
                    }
                }
            }

            return 99;
        },
        
        
        termsLink()
        {
            let cta = vm.getPageById(vm.page.variables.ctaLink),
                path = cta.path.replace('/shop/', '/'),
                tl = vm.page.variables.ctaText;
            
            if (path.charAt(path.length - 1) == '/')
            {
                path = path.slice(0, -1);
            }

            let link = '<a href="' + path + '">' + cta.pagetitle + '</a>';

            tl = tl.replace('%s', link);

            return tl;
        },


        validOrder()
        {
            let addresses = [
                    vm.formBilling,
                    vm.formShipping,
                ];

            if (!vm.termsAccepted) return false;

            if (!vm.user && (!vm.validEmail || !vm.validPassword)) return false;

            for (let i = 0; i < addresses.length; i++)
            {
                let a = addresses[i],
                    keys = Object.keys(a);
                
                for (let j = 0; j < keys.length; j++)
                {
                    let key = keys[j];

                    if (key !== 'address_2' && (a[key] === null || a[key].length <= 0)) return false;
                }
            }

            return true;
        },
    },


    methods: {
        afterLogin()
        {
            if (vm.formBilling.name.length == 0) vm.formBilling.name = vm.user.name;
        },

        
        clickHandler({ target })
        {
            if (target.tagName.toLowerCase() == 'a')
            {
                let href = target.href.replace(target.baseURI, '/');

                if (target.href.indexOf(target.baseURI) >= 0)
                {
                    vm.$router.push({ path: href });
                }
                else
                {
                    window.location.href = href;
                }
            }
            else
            {
                vm.termsAccepted = !vm.termsAccepted;
            }
        },


        filterCountries(filter)
        {
            let filtered = [],
                items = vm.countries;
                // items = vm.countries[vm.$route.params.lang];

            for (let i = 0; i < items.length; i++)
            {
                let item = items[i];

                if (item.country.toLowerCase().indexOf(filter.toLowerCase()) >= 0) filtered.push(item);
            }

            return filtered;
        },


        getCountry(iso)
        {
            // let list = vm.countries[vm.$route.params.lang];
            let list = vm.countries;
            
            for (let i = 0; i < list.length; i++)
            {
                let o = list[i];

                if (o.iso == iso) return o.country;
            }

            return '';
        },


        selectAddress(id, target)
        {
            if (target == 'shipping')
            {
                let name = vm.formShipping.name;

                for (let i = 0; i < vm.user.addresses.length; i++)
                {
                    let a = vm.user.addresses[i];

                    if (a.id == id)
                    {
                        let address = vm.jsCopy(a);
                        address.name = name;
                        vm.formShipping = address;
                    }
                }
            }
            else
            {
                vm.selectedBillingAddress = id;
            }
        },


        setBillingCountry(c)
        {
            if (typeof(c) == 'string') vm.formBilling.country = c;
        },


        setLoginTab(tab)
        {
            vm.loginTab = tab;
        },
        

        // setPaymentMethod(pm)
        // {
        //     vm.paymentMethod = pm;
        // },


        setShippingCountry(c)
        {
            if (typeof(c) == 'string') vm.formShipping.country = c;
        },


        async toPayment()
		{
            if (vm.validOrder)
            {
                vm.processing = true;
                
                let orderData = {
                    billing: vm.formBilling,
                    cart: vm.cart,
                    // lang: vm.$route.params.lang,
                    lang: 'en',
                    payment: vm.paymentMethod,
                    shipping: vm.formShipping,
                };

                orderData.user = vm.user ? vm.user : vm.newUser;

                if (vm.isDev)
                {
                    setTimeout(() => {
                        vm.processing = false;
                    }, 1000);
                }
                else
                {
                    await axios.post(vm.base + 'api/cart/order/', orderData, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    })
                    .then(response => {
                        // vm.updateUser(response.data.success.user);
                        vm.processing = false;
                        if (response.data.url)
                        {
                            window.location.href = response.data.url;
                        }
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
                }
            }
		},
    },

    
    setup()
	{
		const { asMoney, getImgUrl, getPageById, getTranslation, isValidEmail, jsCopy } = useFunctions();
        const { cartInfo, discountTotal, getCartItemName, getCartItemSettings, orderTotal } = useCart();


		return {
            asMoney,
            cartInfo,
            discountTotal,
            getCartItemName,
            getCartItemSettings,
            getImgUrl,
            getPageById,
            getTranslation,
            isValidEmail,
            jsCopy,
            orderTotal,
		};
	},


    created()
    {
        vm = this;

        vm.placeholderBilling = vm.getCountry(vm.formBilling.country);
        vm.placeholderShipping = vm.getCountry(vm.formShipping.country);
    },



    mounted()
    {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })
        

        if (vm.mainAddress.address.length > 0)
        {
            vm.selectedBillingAddress = vm.mainAddress.id;
        }

        if (vm.user && vm.formBilling.name.length == 0)
        {
            vm.formBilling.name = vm.user.name;
        }

        if (vm.user && vm.formShipping.name.length == 0)
        {
            vm.formShipping.name = vm.user.name;
        }
    },


    watch: {
        "formBilling.country": {
            deep: true,
            handler(to)
            {
                vm.filterBilling = '';

                if (to.length > 0) vm.placeholderBilling = vm.getCountry(to);

                vm.openBilling = false;
            },
        },


        formBilling: {
            deep: true,
            handler(to)
            {                
                if (vm.sameShipping) vm.formShipping = vm.jsCopy(to);

                if (!vm.user)
                {
                    vm.newUser.name = to.name;
                }

            },
        },


        "formShipping.country": {
            deep: true,
            handler(to)
            {
                vm.filterShipping = '';

                if (to.length > 0) vm.placeholderShipping = vm.getCountry(to);

                vm.openShipping = false;
            },
        },


        sameShipping(to)
        {
            if (to)
            {
                vm.formShipping = vm.jsCopy(vm.formBilling);
            }
            else
            {
                vm.formShipping = {
                    name: vm.user ? vm.user.name : '',
                    address: '',
                    address_2: '',
                    postcode: '',
                    city: '',
                    country: vm.formBilling.country,
                };
            }
        },


        selectedBillingAddress(to)
        {
            let name = vm.formBilling.name;

            if (to && to != 0)
            {
                for (let i = 0; i < vm.user.addresses.length; i++)
                {
                    let a = vm.user.addresses[i];

                    if (a.id == to)
                    {
                        let address = vm.jsCopy(a);
                        address.name = name;
                        vm.formBilling = address;
                    }
                }
            }
        },


        "user.name": {
            deep: true,
            handler(to) {
                // If there's a main address, set it by default
                if (Object.prototype.hasOwnProperty.call(vm.mainAddress, 'id'))
                {
                    vm.selectedBillingAddress = vm.mainAddress.id;
                }
                
                // If there's no name set yet, set the user's name by default
                if (!vm.formBilling.name || vm.formBilling.name.length == 0) {
                    vm.formBilling.name = to;

                    if (!vm.sameShipping && (!vm.formShipping.name || vm.formShipping.name.length == 0)) {
                        vm.formShipping.name = to;
                    }
                }
            },
        },
    }
}
</script>