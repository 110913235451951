<template>
    <div class="address" v-if="extended">
        
        <slot></slot>

        <ul>
            <li v-if="addressName">
                {{ addressName }}
            </li>
            <li>
                {{ address.address }}
            </li>
            <li v-if="address.address_2">
                {{ address.address_2 }}
            </li>
            <li>
                {{ address.postcode }}, {{ address.city }}
            </li>
            <li>
                {{ getCountry(address.country) }}
            </li>
        </ul>

    </div>


    <div class="address-preview" v-else>
        
        <strong v-if="addressName" v-html="addressName" />

        <span v-html="compact" />

    </div>
</template>


<script>
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        address: Object,
        extended: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
    },


    computed: {
        addressName()
        {
            if (Object.keys(vm.address).indexOf('name') >= 0) return vm.address.name;
            else if (vm.name != '') return vm.name;

            return null;
        },

        
        compact()
        {
            let address = vm.address.address_2 ? [vm.address.address, vm.address.address_2].join(' ') : vm.address.address,
                info = [
                    address,
                    [vm.address.postcode, vm.address.city].join(' '),
                    vm.address.country,
                ];
            
            return info.join(', ');
        },
    },


    created()
    {
        vm = this;
    },

    
    setup()
	{
		const { getCountry } = useFunctions();


		return {
            getCountry,
		};
	},
};
</script>