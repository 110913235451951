import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default function usePage() {
	const route = useRoute();
	const store = useStore();


	const allPages = computed(() => {
		return store.state.pages;
    });


    const downloads = computed(() => {
        return store.state.downloads;
    });


    const photos = computed(() => {
        return store.state.photos;
    });


    const sets = computed(() => {
        return store.state.sets;
    });


	const page = computed(() => {
        // NOTE: The lines below are obsolete, in a single language shop
		// Before landing on a home page, use a temporary loaded
		// if (typeof(route.params.lang) == 'undefined') return { temp: true };

		// Get the pages for the current language
		let modalPaths = [
            'account/order',
            'downloads',
				// 'fotoproducten',
				// 'photo-gifts',
                'presets',
				// 'prints',
			],
            // pages = allPages.value[route.params.lang],
            pages = allPages.value,
			page = {},
			routePath = route.path;
        
        if (routePath.slice(-1) != '/') routePath += '/';
		

		// For print and product pages, get the overview page
		for (let i = 0; i < modalPaths.length; i++)
		{
			let splitter = '/' + modalPaths[i] + '/';

			if (routePath.split(splitter).length > 1)
			{
				routePath = routePath.split(splitter)[0] + splitter;
			}
		}

		if (typeof (pages) != 'undefined')
		{
			for (let i = 0; i < pages.length; i++)
			{
                let p = pages[i],
                pPath = p.path;
				
				if (pPath.indexOf(p.base) < 0) pPath = p.base + pPath;
				if (pPath.substr(-2) == '//') pPath = pPath.substr(0, pPath.length - 2);
	
                if (pPath.substr(0, 5) == '/shop') pPath = pPath.substr(5);
                if (pPath.substr(0, 4) == '/en/') pPath = pPath.substr(3);
	
				if (routePath == pPath) page = p;
			}
	
			if (page)
			{
				// Create some additional paramters
				page.hasImage = Object.keys(page).indexOf('variables') >= 0 && Object.keys(page.variables).indexOf('image') >= 0;
                page.title = page.longtitle && page.longtitle.length > 0 ? page.longtitle : page.pagetitle;
			}
		}
        else
        {
            console.log('* Pages are undefined');
        }
		
		return page ? page : {};
	});


	const photo = computed(() => {
		// Get the pages for the current language
		let photo = null,
			routePath = route.path + '/';
		

		// For print pages, get the prints overview
        if (
            routePath.split('/prints/').length > 1 ||
            routePath.split('/photo-gifts/').length > 1 || routePath.split('/fotoproducten/').length > 1 ||
            routePath.split('/presets/').length > 1 ||
            routePath.split('/sets/').length > 1
        )
		{
            let list = photos;
            
            if (routePath.split('/presets/').length > 1) list = downloads;
            if (routePath.split('/sets/').length > 1) list = sets;

			for (let i = 0; i < list.value.length; i++)
			{
				let p = list.value[i];

				if (p.alias == route.params.alias) photo = p;
			}
		}
		// For products, get the overview page
		// else if (routePath.split('/photo-gifts/').length > 1 || routePath.split('/fotoproducten/').length > 1)
		// {
		// 	for (let i = 0; i < page.value.sections.length; i++)
		// 	{
		// 		let s = page.value.sections[i];

		// 		if (s.alias == route.params.alias) photo = s;
		// 	}
		// }

		
		return photo;
	});


	return {
		page: page,
		photo: photo,
	};
}