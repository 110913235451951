<template>
    <div class="content">
        <div class="buttons">
            <router-link class="button" v-for="(s, id) in tabs" :key="['btn', id].join('-')" :to="to(s)">
                {{ s.pagetitle }}
            </router-link>
        </div>
    </div>
</template>


<script>
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: Object,
    },


    computed: {
        tabs()  
        {
            let sections = vm.page.sections,
                tabs = [];

            for (let i = 0; i < sections.length; i++)
            {
                let s = sections[i];

                if (s.hidemenu == 0)
                {
                    tabs.push(s);
                }
            }

            return tabs;
        },
    },

    
    setup()
	{
		const { to } = useFunctions();


		return {
            to,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>