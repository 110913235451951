<template>

    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main v-if="!user">
                <section class="container">
                    <LoginForm :isLogin="isLogin" @toggle="toggleForm">
                        <h1>
                            {{ getTranslation('login') }}
                        </h1>
                    </LoginForm>
                </section>
            </main>
        
        
            <main v-else class="container">
                <aside>
                    <AccountNav @logout="logoutUser">
                        <h2>
                            {{ getTranslation('title_account') }}
                        </h2>
                    </AccountNav>
                </aside>
        
                <transition name="fade" mode="out-in">
                    <section class="container tab" :key="$route.path">
                        <h2>
                            {{ page.longtitle }}
                        </h2>
        
                        <div v-if="page.content.length > 0" class="content" v-html="page.content" />
        
                        <component :is="tabComponent" :page="page" />
        
                    </section>
                </transition>
        
        
            </main>


            <slot name="footer" />
        </div>
    </div>

</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import AccountNav from '@/components/AccountNav.vue';
import LoginForm from '@/components/LoginForm.vue';
import TabAccount from '@/components/TabAccount.vue';
import TabOrder from '@/components/TabOrder.vue';
import TabOrders from '@/components/TabOrders.vue';
import TabUser from '@/components/TabUser.vue';
import TabWebhook from '@/components/TabWebhook.vue';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        AccountNav,
        LoginForm,
        TabAccount,
        TabOrder,
        TabOrders,
        TabUser,
        TabWebhook,
    },


    data()
    {
        return {
            isLogin: true,
            smoother: null,
        }
    },


    computed: {
        ...mapGetters([
            'accountPage',
            'isDev',
            'user',
        ]),


        tabComponent()
        {
            let tc = 'TabAccount';

            if (vm.page.template == 19)
            {
                tc = 'TabWebhook';
            }
            else if (vm.$route.name != 'MyAccount')
            {
                let type = vm.page.variables.tabType;

                tc = 'Tab' + type[0].toUpperCase() + type.substr(1);
            }

            return tc;
        },
    },


    methods: {
        ...mapActions([
            'getUserData',
        ]),


        logoutUser()
        {
            vm.$emit('logout');
        },


        toggleForm()
        {
            vm.isLogin = !vm.isLogin;
        },
    },

    
    setup()
	{
		const { getTranslation } = useFunctions();


		return {
            getTranslation,
		};
	},


    created()
    {
        vm = this;


        if (!vm.isDev)
        {
            vm.getUserData();
        }
    },


    mounted() {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })
    },
    

    watch: {
        $route ()
        {
            if (!vm.isDev)
            {
                vm.getUserData();
            }
        },
    }
}
</script>