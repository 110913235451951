import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default function useFunctions() {
	const route = useRoute();
	const router = useRouter();
	const store = useStore();

	const base = computed(() => store.state.base);
    const pages = computed(() => store.state.pages);
    const photos = computed(() => store.state.photos);
	const translations = computed(() => store.state.translations);

	
	function asMoney(input)
	{
		if (typeof(input) != 'number') return input;

		let output = (input / 100).toFixed(2).toString();

		output = output.replace('.', ',');

		return output;
	}


	function closeModal()
	{
		let p = route.path.split('/');
		p.pop();
		
		let path = p.join('/');

		if (router.options.history.state.back != path)
		{
			router.push(path);
		}
		else
		{
			router.go(-1);
		}
	}


	function formatDate(input)
	{
		let d = new Date(input);

		return [d.getDate().toString().padStart(2, '0'), (d.getMonth() + 1).toString().padStart(2, '0'), d.getFullYear()].join('-');
	}


	function getCountry(iso)
	{
        // let list = store.getters.countries[route.params.lang];
        let list = store.getters.countries;

		for (let i = 0; i < list.length; i++)
		{
			let c = list[i];

			if (c.iso == iso) return c.country;
		}

		return iso;
	}

        
	function getImgUrl(page, prop)
	{
        if (typeof(prop) == 'undefined') prop = 'image';

		return base.value + '../' + page.variables[prop];
	}


	function getListItemById(id, list)
	{
		// Loop through all list items
		for (let i = 0; i < list.length; i++)
		{
			let p = list[i];
			
		
			// If a match is found, return it
			if (p.id == id) return p;
		}
		
		
		// If no match was found, return false
		return false;
	}


	function getMinPrice(page, salePrice)
	{
        let min = null,
            options = page.variables.printOptions.split('||'),
			priceProp = salePrice ? 'salePrice' : 'price';

		for (let i = 0; i < options.length; i++)
		{
			let o = options[i];
			
			let page = getPrintOptionById(o);

			if (page !== false)
			{
				if (min)
				{
					min = Math.min(min, page.variables[priceProp]);
				}
				else
				{
					min = page.variables[priceProp];
				}
			}
		}

		return min;
	}


	function getOrderStatus(o)
	{
		for (let i = 0; i < store.getters.orderStatuses.length; i++)
		{
			let os = store.getters.orderStatuses[i];

			if (os.id == o.fk_order_status) return getTranslation(os.tag);
		}

		return false;
	}

        
	function getPageById(id)
	{
        // let lang = route.params.lang,
        //     list = pages.value[lang];
        let list = pages.value;

        for (let i = 0; i < list.length; i++)
        {
            let p = list[i];

            if (p.id == id)
            {
                return p;
            }
        }
	}

        
	function getPhotoById(id)
	{
        let list = photos.value;

        for (let i = 0; i < list.length; i++)
        {
            let p = list[i];

            if (p.id == id)
            {
                return p;
            }
        }
	}


	function getPrintOptionById(id)
	{
		let list = store.state.prints,
			keys = Object.keys(list);

		// Loop through all list items
		for (let i = 0; i < keys.length; i++)
		{
			let p = list[keys[i]];
		
			// If a match is found, return it
			if (p.id == id) return p;
		}
		
		
		// If no match was found, return false
		return false;
	}


	function getTranslation(tag, lang = null, params = [])
	{
		if (lang === null)
		{
            // lang = route.params.lang;
            lang = 'en';
		}
		
		if (lang == 'test')
		{
			console.log('This is a translation test...');
		}

        let tr = Object.prototype.hasOwnProperty.call(translations.value, tag) ? translations.value[tag] : tag;
        let t = tag;

        // If the tag didn't exist, create a new tag
        if (tr == tag)
        {
            store.dispatch('createTranslation', tag);
        }
        // Otherwise, find the right translation
        else
        {
            t  = Object.prototype.hasOwnProperty.call(tr, lang) ? tr[lang] : tag;
        }

        // Fill in all potential placeholders
		for (let i = 0; i < params.length; i++)
		{
			t = t.replace(/%s/, params[i]);
		}
		
		return t;
	}


	function isValidEmail(email)
	{
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}


    function isValidPassword(pw)
    {
        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
        return re.test(String(pw));
    }


	function jsCopy(input)
	{
		return JSON.parse(JSON.stringify(input))
	}


	function to (item, lang)
	{
        // if (typeof (lang) == 'undefined') lang = route.params.lang;
        if (typeof (lang) == 'undefined') lang = 'en';

		let to = {
            name: 'DefaultPage',
            params: {
                lang: lang,
            },
        };

        let name = Object.prototype.hasOwnProperty.call(item, 'name') ? item.name : item.pagetitle;

        switch (parseInt(item.template))
		{
			case 4:
                to.name = name + 'Overview';
				break;


			case 7:
                to.name = 'ContactPage';
				break;


			case 12:
                to.name = 'HomePage';
				break;


			case 13:
                to.name = 'Customization' + lang.toUpperCase();
                break;

			case 14:
                to.name = item.alias == 'presets' ? 'PresetsOverview' : 'ProductsOverview' + lang.toUpperCase();
				break;


            case 15:
                to.name = 'ProductPage' + lang.toUpperCase();
                to.params.alias = item.alias;
                break;

			case 17:
				to.name = 'MyAccount';
				break;


			case 18:
                to.name = 'MyAccountTab';
                to.params.alias = item.alias;
				break;

			case 22:
                to.name = 'Cart' + lang.toUpperCase();
				break;

			case 23:
				to.name = 'Order' + lang.toUpperCase();
				break;

			case 24:
				to.name = 'ArtReferences' + lang.toUpperCase();
				break;


			default:
                to.params.alias = item.alias;
				break;
		}

		return to;
	}


	function vatMath(items, output = 'total')
	{
		let t = 0;

		for (let i = 0; i < items.length; i++)
		{
			let conv = 1,
				oi = items[i];

			switch (output)
			{
				case 'subtotal':
					conv = (100 + oi.vat) / 100;
					break;

				case 'vat':
					conv = (100 / (100 + oi.vat)) - 1;
					conv = 1 / (conv * -1);
					break;
			}

			// Multiply the quantity by the price * 100 to convert to euros * VAT percentage
			t += parseInt(oi.quantity) * parseFloat(oi.price) * 100 / conv;
		}

		return t;
	}

	
	return {
		asMoney,
		closeModal,
		formatDate,
		getCountry,
		getImgUrl,
		getListItemById,
		getMinPrice,
		getOrderStatus,
        getPageById,
        getPhotoById,
		getPrintOptionById,
		getTranslation,
		isValidEmail,
        isValidPassword,
		jsCopy,
		to,
		vatMath,
	};
}