<template>
    <div id="smooth-wrapper">
        <div id="smooth-content">
            
            <main class="contact">
                <section class="contact" id="contact">
        
                    <aside v-if="vars.hasOwnProperty('contactName')" class="business">
                        <h4 v-html="vars.contactName.split('|').join('<br />')" />
        
                        <ul>
                            <li>
                                <address>
                                    {{ vars.contactAddress }}
                                    <br />
                                    {{ vars.contactPostcode }}
                                    {{ vars.contactCity }},
                                    {{ vars.contactCountry }}
                                </address>
                            </li>
                            <li>
                                <a :href="vars.contactEmail">
                                    {{ vars.contactEmail }}
                                </a>
                            </li>
                            <li>
                                <strong>
                                    {{ getTranslation('contact_cofc') }}
                                </strong>
        
                                {{ vars.contactCofC }}
                            </li>
                            <li>
                                <strong>
                                    {{ getTranslation('contact_vat') }}
                                </strong>
        
                                {{ vars.contactVAT }}
                            </li>
                        </ul>
                    </aside>
        
        
                    <div class="container">
                        <h1>
                            {{ page.pagetitle }}
                        </h1>
        
                        <h2 v-if="page.longtitle.length > 0">
                            {{ page.longtitle }}
                        </h2>
        
                        <div class="content" v-if="page.content.length > 0" v-html="page.content" />
        
                        <contact-form :page="page" />
                    </div>
        
                </section>
            </main>


            <slot name="footer" />
        </div>
    </div>

</template>


<script>
import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import ContactForm from '@/components/ContactForm.vue';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    components: {
		ContactForm,
	},


    props: {
        page: Object,
    },


    data()
    {
        return {
            smoother: null,
        }
    },


    computed: {
        vars()
        {
            return vm.page.variables;
        },
    },

    
    setup()
	{
		const { getTranslation } = useFunctions();


		return {
            getTranslation,
		};
	},


    created()
    {
        vm = this;
    },


    mounted() {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })
    },
}
</script>