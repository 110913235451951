<template>
    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main v-if="photo && photos.length > 0">
                <section class="product">
                    <div class="img" :style="mockupWidthVar" :data-material="selected.material.alias"
                        :data-mockup="mockupWidth >= 50 ? 1 : 2" data-size="4x5">
                        <img v-if="mockup" class="mockup" :src="mockupImg" />
        
                        <ProductImage id="item" :photo="photo" />
        
                        <div class="mockup-toggle">
                            <a href="#" :class="!mockup ? 'active' : ''" @click.prevent="mockup = false"
                                :aria-label="getTranslation('toggle_mockup_hide')"
                                :title="getTranslation('toggle_mockup_hide')">
                                <font-awesome-icon icon="fa-sharp fa-solid fa-image-portrait" />
                            </a>
        
                            <a href="#" :class="mockup ? 'active' : ''" @click.prevent="mockup = true"
                                :aria-label="getTranslation('toggle_mockup_show')"
                                :title="getTranslation('toggle_mockup_show')">
                                <font-awesome-icon icon="fa-sharp fa-solid fa-house-chimney" />
                            </a>
                        </div>
                    </div>
        
        
                    <product-form :cartButtonState="cartButtonState" :product="photo" :productPrice="productPrice"
                        :quantity="quantity" @addToCart="addToCart" @changeQuantity="changeQuantity">
        
                        <template v-slot:materials>
                            <BlendInSelect :active="Object.keys(materialOptions).length > 1" :description="materialDescription">
                                <template v-slot:label>
                                    {{ getTranslation('label_material') }}
                                </template>
        
                                <template v-slot:select v-if="Object.keys(materialOptions).length > 1">
                                    <select v-model="material">
                                        <option v-for="(o, id) in materialOptions" :key="['mat', id].join('-')" :value="o.id">
                                            {{ o.pagetitle }}
                                        </option>
                                    </select>
                                </template>
        
                                <template v-slot:selected>
                                    {{ materialName }}
                                </template>
                            </BlendInSelect>
                        </template>
        
                        <template v-slot:sizes>
                            <BlendInSelect :active="filteredPrintOptions.length > 1">
                                <template v-slot:label>
                                    {{ getTranslation('label_size') }}
                                </template>
        
                                <template v-slot:select>
                                    <select v-model="printOption">
                                        <option v-for="(o, id) in filteredPrintOptions" :key="['mat', id].join('-')"
                                            :value="o.id">
                                            {{ o.pagetitle.replace(materialName, '') }}
                                        </option>
                                    </select>
                                </template>
        
                                <template v-slot:selected>
                                    {{ selected.printOption.pagetitle.replace(materialName, '') }}
                                </template>
                            </BlendInSelect>
                        </template>
        
                    </product-form>
        
                </section>
            </main>
        
            <main v-else>
                <section>
                    <font-awesome-icon icon="fa-sharp fa-regular fa-spinner-third" spin size="2xl" />
                </section>
            </main>

            <slot name="footer" />
        </div>
    </div>
</template>


<script>
import supportsWebP from 'supports-webp';

import { mapActions, mapGetters } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import useFunctions from '@/hooks/functions.js';

import BlendInSelect from '@/components/BlendInSelect.vue';
import ProductForm from '@/components/ProductForm.vue';
import ProductImage from '@/components/ProductImage.vue';

var vm;

export default {
    props: {
        page: Object,
        photo: Object,
    },


    components: {
        BlendInSelect,
        ProductForm,
        ProductImage,
    },


    data()
    {
        return {
            adding: false,
            defaults: {
                material: 89,
                quantity: 1,
            },
            material: 0,
            mockup: false,
            printOption: null,
            quantity: 0,
            smoother: null,
            webp: true,
        };
    },


    computed: {
        ...mapGetters([
            'materials',
            'prints',
        ]),


        cartButtonState()
        {
            if (vm.adding) return false;
            if (vm.material === null) return false;
            if (vm.photo === null) return false;
            if (vm.printOption === null) return false;
            if (vm.quantity < 1) return false;

            return true;
        },
        

        filteredPrintOptions()
        {
            let filtered = [];

            for (let i = 0; i < vm.printOptions.length; i++)
            {
                let po = vm.printOptions[i];

                if (po.variables.material == vm.material)
                {
                    filtered.push(po);
                }
            }

            if (filtered.length > 0)
            {
                vm.printOption = filtered[0].id;
            }

            return filtered;
        },


        materialDescription()
        {
            // let prop = vm.$route.params.lang == 'nl' ? 'introtext' : 'description';

            // return vm.materialOptions[vm.material][prop];

            return vm.materialOptions[vm.material].description;
        },


        materialOptions()
        {
            let mo = {};

            if (vm.photo)
            {
                for (let i = 0; i < vm.materials.length; i++)
                {
                    let m = vm.materials[i];

                    for (let j = 0; j < vm.printOptions.length; j++)
                    {
                        let po = vm.printOptions[j],
                            moKeys = Object.keys(mo);

                        if (po.variables.material == m.id && moKeys.indexOf(po.variables.material) < 0)
                        {
                            mo[po.variables.material] = m;
                        }
                    }
                }
            }

            return mo;
        },


        materialName()
        {
            return vm.materialOptions[vm.material].pagetitle;
        },


        mockupImg()
        {
            let extension = vm.webp ? '.webp' : '.jpg',
                img = vm.mockupWidth >= 50 ? 1 : 2,
                url = 'images/mockups/' + vm.photo.variables.imgRatio + '-' + img + extension;

            return url;
        },


        mockupWidth()
        {
            return vm.selected.printOption.variables.width;
        },


        mockupWidthVar()
        {
            return `--mockup-width: ${vm.mockupWidth}`;
        },


        photos()
        {
            let ids = vm.photo.variables.photos.split('||'),
                photos = [];
            
            for (let i = 0; i < ids.length; i++)
            {
                let p = vm.getPhotoById(ids[i]);

                if (p) photos.push(p);
            }

            return photos;
        },


        printOptions()
        {
            let po = [];

            if (vm.photos.length > 0)
            {
                let options = vm.photos[0].variables.printOptions.split('||'),
                    keys = Object.keys(vm.prints);
                
                for (let i = 0; i < keys.length; i++)
                {
                    let p = vm.prints[keys[i]];

                    if (options.indexOf(p.id) >= 0)
                    {
                        po.push(p);
                    }
                }
            }

            return po;
        },


        productPrice()
        {
            let opt = null;

            for (let i = 0; i < vm.printOptions.length; i++)
            {
                let po = vm.printOptions[i];

                if (po.id == vm.printOption)
                {
                    opt = po;
                }
            }

            if (!opt) return 0;
            
            let full = opt ? opt.variables.price * vm.quantity * vm.photos.length : 0,
                margin = opt.variables.price - opt.variables.costs,
                discount = (margin * vm.photos.length) / 3,
                price = full - discount;

            // Conversion to make it a nice and even number
            // price = (Math.round(price / 1000) * 1000) - 5;
            price = (Math.round(price / 1000) * 1000);

            return price;
        },


        selected()
        {
            let po = vm.printOptions[0];
            
            for (let i = 0; i < vm.printOptions.length; i++)
            {
                let p = vm.printOptions[i];
                
                if (p.id == vm.printOption) po = p;
            }

            return {
                material: vm.materialOptions[vm.material],
                printOption: po,
            };
        },
    },


    methods: {
        ...mapActions([
            'addCartItems',
        ]),


        addToCart()
        {
            if (vm.cartButtonState)
            {
                let cartObjects = [];

                cartObjects.push({
                    photo: vm.photo.id,
                    product: vm.printOption,
                    quantity: vm.quantity,
                    digital: 0,
                });

                vm.adding = true;

                // vm.$nextTick(() => {
                    // setTimeout(() => {
                        vm.addCartItems(cartObjects)
                        .then(() => {
                            // Reset the defaults again
                            vm.resetDefaults();
                            vm.adding = false;
                            vm.$emit('cartUpdate');
                        })
                    // }, 1.5 * vm.duration);
                // });
            }
        },


        changeQuantity(modifier)
        {
            let q = vm.quantity + modifier;

            if (q < 1) q = 1;

            vm.quantity = q;
        },


        resetDefaults()
        {
            vm.material = vm.defaults.material;
            vm.quantity = vm.defaults.quantity;
        },
    },

    
    setup()
	{
		const { asMoney, getPhotoById, getTranslation } = useFunctions();


		return {
            asMoney,
            getPhotoById,
            getTranslation,
		};
	},


    created()
    {
        vm = this;

        supportsWebP.then(supported => {
            vm.webp = supported;
        });

        vm.resetDefaults();
    },


    mounted()
    {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });

                let header = document.querySelector('header');

                if (header.offsetTop == 0)
                {
                    // Pin the image
                    ScrollTrigger.create({
                        trigger: ".form",
                        pin: ".img",
                        start: `top ${header.clientHeight}px`,
                        pinSpacing: false,
                    });
                }
            }
        })
    },
}
</script>