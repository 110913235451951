<template>
    <div class="payment-methods">
        <div v-for="(pm, id) in paymentMethods" class="payment-method" :key="id">
            <img :alt="pm.pagetitle" :src="base + pm.variables.logo" />

            <img v-if="pm.alias == 'creditcard'" :alt="pm.pagetitle" :src="base + pm.variables.logo.replace('mastercard', 'visa')" />
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'base',
            'paymentMethods',
        ]),
    },
}
</script>