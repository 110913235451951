import { createStore } from "vuex";
import { useRoute } from "vue-router";

import axios from 'axios';
// import { resolve } from "core-js/es6/promise";

var base = 'https://www.pascalmaranus.com/';

export default createStore({
    state: {
		base: base,
		cart: [],
		categories: [],
        conversions: {
            'EUR': 1,
            'GBP': 0.85,
            'USD': 1.2,
        },
        countries: [],
        currency: 'EUR',
		discount: null,
		downloads: [],
        duration: 650,
		isDev: process.env.NODE_ENV == 'development',
		languages: [],
		locations: [],
		materials: [],
		orderStatuses: [],
		pages: {},
		paymentMethods: [],
		photos: [],
        prints: [],
        sets: [],
		shippingRanges: [],
		siteName: 'Pascal Maranus',
        sizes: [
            {
                'key': 'imageSmall',
                'restriction': '(max-width: 1023px)',
            },
            {
                'key': 'image',
                'restriction': '(min-width: 1024px)',
            },
        ],
        structure: {},
        texts: {},
        translations: {},
		user: null,
	},
	
	
	getters: {
		accountPage: state => {
            let pages = state.pages;
			// let route = useRoute(),
            //     pages = state.pages[route.params.lang];
				
			for (let i = 0; i < pages.length; i++)
			{
				let p = pages[i];

				if (p.template == 17)
				{
					return p;
				}
			}

			return false;
		},
		
		
        allProducts: state => {
            // let languages = Object.keys(state.pages);
            let products = [];
				
			// for (let i = 0; i < languages.length; i++)
			// {
                // let pages = state.pages[languages[i]];
                let pages = state.pages;

				for (let j = 0; j < pages.length; j++)
                {
                    let p = pages[j];

                    if (p.template == 15)
                    {
                        products.push(p);
                    }
                }
			// }

			return products;
        },


		base: state => {
			return state.base;
		},
		
		
		cart: state => {
			return state.cart;
		},
		
		
		categories: state => {
			return state.categories;
		},
		
		
		countries: state => {
			return state.countries;
		},
		
		
		currency: state => {
			return state.currency;
		},
		
		
		discount: state => {
			return state.discount;
        },


        downloads: state => {
            return state.downloads;
        },


        duration: state => {
            return state.duration;
        },
		
		
		imgBase: state => {
			return state.base.replace('/shop/', '/');
		},
		
		
		isDev: state => {
			return state.isDev;
		},
		
		
		languages: state => {
			return state.languages;
		},
		
		
		locations: state => {
			return state.locations;
		},
		
		
		materials: state => {
			return state.materials;
		},
		
		
		orderStatuses: state => {
			return state.orderStatuses;
		},
		
		
		pages: state => {
			return state.pages;
		},


		paymentMethods: state => {
			return state.paymentMethods;
		},
		
		
		photos: state => {
			return state.photos;
		},
		
		
		prints: state => {
			return state.prints;
		},
		
		
		products: (state, getters) => {
			let allProducts = getters.allProducts,
                products = [],
				route = useRoute();
				
			for (let i = 0; i < allProducts.length; i++)
			{
				let p = allProducts[i];

				if (p.language == route.params.lang)
				{
					products.push(p);
				}
			}

			return products;
        },


        sets: state => {
            return state.sets;
        },


		shippingRanges: state => {
			return state.shippingRanges;
		},
		
		
		siteName: state => {
			return state.siteName;
		},
		
		
		sizes: state => {
			return state.sizes;
		},
		
		
		structure: state => {
			return state.structure;
        },


        texts: state => {
            return state.texts;
        },


        translations: state => {
            return state.translations;
        },
		
		
		user: state => {
			return state.user;
		},
	},


	mutations: {
		setCart (state, value)
		{
			state.cart = value;
		},
		
		
		setCategories (state, value)
		{
			state.categories = value;
		},
		
		
		setCountries (state, value)
		{
			state.countries = value;
		},
		
		
		setDiscount (state, value)
		{
            if (value == '') value = null;
            
			state.discount = value;
        },


        setDownloads(state, value)
        {
            state.downloads = value;
        },
		
		
		setLanguages (state, value)
		{
			state.languages = value;
		},
		
		
		setLocations (state, value)
		{
			state.locations = value;
		},
		
		
		setMaterials (state, value)
		{
			state.materials = value;
		},
		
		
		setOrderStatuses (state, value)
		{
			state.orderStatuses = value;
		},
		
		
		setPages (state, value)
		{
			state.pages = value;
		},


		setPaymentMethods (state, value)
		{
			state.paymentMethods = value;
		},
		
		
		setPhotos (state, value)
		{
			state.photos = value;
		},
		
		
		setPrints (state, value)
		{
			state.prints = value;
		},
		
		
		setSets (state, value)
		{
			state.sets = value;
		},
		
		
		setShippingRanges (state, value)
		{
			state.shippingRanges = value;
		},
		
		
		setStructure (state, value)
		{
			state.structure = value;
		},


		setTexts(state, value)
		{
			state.texts = value;
		},


		setTranslations(state, value)
		{
			state.translations = value;
		},


		setUser(state, value)
		{
			state.user = value;
		},
	},


	actions: {
        _initialize: async (context, payload) => {
			try {
                const response = await axios.get(base + 'api/initialize-single/', { params: payload });

                if (Object.prototype.hasOwnProperty.call(payload, 'request'))
                {
                    switch(payload.request)
                    {
                        case 'language':
                            context.commit('setCountries', response.data.countries);
                            context.commit('setTexts', response.data.texts);
                            break;

                        case 'products':
                            context.commit('setDownloads', response.data.downloads);
                            context.commit('setPhotos', response.data.photos);
                            context.commit('setSets', response.data.sets);
                            break;

                        case 'shop':
                            context.commit('setCategories', response.data.categories);
                            context.commit('setDiscount', response.data.discount);
                            context.commit('setLocations', response.data.locations);
                            context.commit('setMaterials', response.data.materials);
                            context.commit('setPrints', response.data.prints);
                            break;
                            
                        case 'order':
                            context.commit('setCart', response.data.cart);
                            context.commit('setOrderStatuses', response.data.orderStatuses);
                            context.commit('setPaymentMethods', response.data.paymentMethods);
                            context.commit('setShippingRanges', response.data.shippingRanges);
                            context.commit('setUser', response.data.user);
                            break;
                        
                        default:
                            context.commit('setLanguages', Object.keys(response.data.structure));
                            context.commit('setPages', response.data.pages);
                            context.commit('setStructure', response.data.structure);

                            if (response.data.translations)
                            {
                                context.commit('setTranslations', response.data.translations);
                            }
                            
                            break;
                    }
                }
            } catch (error) {
                console.log(error);
            }
		},


		addCartItems: (context, payload) => {
			return new Promise((resolve) => {
				let cart = context.state.cart;
	
				for (let i = 0; i < payload.length; i++)
				{
					let exists = false,
						item = payload[i];
	
					for (let j = 0; j < cart.length; j++)
					{
						let ci = cart[j];
	
						// If the item exists in the cart, update the quantity
						if (ci.product == item.product && ci.photo == item.photo)
						{
							exists = true;
							ci.quantity += item.quantity;
							cart[j] = ci;
						}
					}
	
					// If the item doesn't exist yet, add it to the cart as a new one
					if (!exists)
					{
						cart.push(item);
					}
				}
	
				context.dispatch('updateCart', cart);

				resolve();
			});
		},


        createTranslation: async (context, payload) => {
            let formData = {
                action: 'createTranslation',
                data: JSON.stringify({
                    tag: payload,
                }),
            };

            try {
                return await axios.post(base + 'api/forms/', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });
            } catch (error) {
                console.log(error);
            }
        },


		getProjectTypes: (context, payload) => {
			// Check if there are any types set
			if (Object.prototype.hasOwnProperty.call(payload, 'types'))
			{
				let types = payload.variables.types.split('||');
				
				return types.join(', ');
			}


			// If nothing was found, return an empty string
			return '';
		},


        getUserData: async ({ commit }, payload) => {
			try {
                const response = await axios.get(base + 'api/user/', { params: payload });
                commit('setUser', response.data);
            } catch (error) {
                console.log(error);
            }
		},


		logout: async ({ state, commit }) => {
			if (state.isDev)
			{
				commit('setUser', null);
			}
			else
			{
				try {
                    const response = await axios.post(base + 'api/user/logout/', { user: null });
                    commit('setUser', response.data);
                } catch (error) {
                    console.log(error);
                }
			}
		},


		removeCartItem: (context, payload) => {
			let cart = context.state.cart,
				newCart = [];

			for (let i = 0; i < cart.length; i++)
			{
				if (i != payload)
				{
					newCart.push(cart[i]);
				}
			}

			context.dispatch('updateCart', newCart);
		},


		updateCart: async ({ state, commit }, payload) => {
			if (state.isDev)
			{
				commit('setCart', payload);
			}
			else
			{
				try {
                    const response = await axios.post(base + 'api/cart/update/', { cart: payload });
                    commit('setCart', response.data);
                } catch (error) {
                    console.log(error);
                }
			}
		},


		updateDiscount: ({ commit }, payload) => {
			return new Promise((resolve) => {
				commit('setDiscount', payload);
				resolve();
			});
		},


		updateUser: ({ commit }, payload) => {
			return new Promise((resolve) => {
				commit('setUser', payload);
				resolve();
			});
		},
	},
});
