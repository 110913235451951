<template>

    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main class="home">
                <section class="content home" :style="images">
                    <picture class="background">
                        <source v-for="(img, id) in images" :key="id" :media="img.restriction" :srcset="imgBase + img.url" />
        
                        <img loading="lazy" :src="imgBase + page.variables.heroSmallFallback" alt="" />
                    </picture>
        
                    <div class="text">
        
                        <h1 v-html="page.longtitle" />
        
                        <router-link class="button" v-if="cta !== false" :to="to(cta)">
                            {{ page.variables.ctaText }}
                        </router-link>
                    </div>
        
                </section>
        
        
                <section class="content products">
        
                    <h2>
                        {{ page.variables.sectionTitle }}
                    </h2>
        
        
                    <div class="items">
                        <product-preview class="featured-product" v-for="(pr, id) in featuredProducts" :key="id"
                            :photo="pr" />
    
                        <div class="featured-product" v-if="photos.length === 0">
                            <font-awesome-icon icon="fa-sharp fa-regular fa-spinner-third" spin size="2xl" />
                        </div>
                    </div>
        
                    <div class="tools">
                        <router-link :to="{ name: 'PrintsOverview' }" class="button">
                            {{ getTranslation('check_all_photos') }}
                        </router-link>
                    </div>
        
                </section>
        
        
                <section class="content white">
                    <div class="text" v-html="page.content" />
        
                    <div class="tools" v-if="cta2 !== false">
                        <router-link class="button" :to="to(cta2)">
                            {{ page.variables.ctaText2 }}
                        </router-link>
                    </div>
                </section>
            </main>


            <slot name="footer" />
        </div>
    </div>

</template>


<script>
import { mapGetters } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import useFunctions from '@/hooks/functions.js';

import ProductPreview from '@/components/ProductPreview.vue';

var vm;

export default {
    props: {
        page: Object,
        webp: Boolean,
    },


    components: {
        ProductPreview,
    },


    data()
    {
        return {
            smoother: null,
        }
    },


    computed: {
        ...mapGetters([
            'base',
            'imgBase',
            'pages',
            'photos',
        ]),


        cta()
        {
            if (Object.keys(vm.page.variables).indexOf('ctaLink') < 0) return false;

            let cta = {};

            // for (let i = 0; i < vm.pages[vm.$route.params.lang].length; i++)
            for (let i = 0; i < vm.pages.length; i++)
            {
                // let p = vm.pages[vm.$route.params.lang][i];
                let p = vm.pages[i];

                if (p.id == vm.page.variables.ctaLink)
                {
                    cta = p;
                }
            }        

            return cta;
        },


        cta2()
        {
            if (Object.keys(vm.page.variables).indexOf('ctaLink2') < 0) return false;

            let cta = {};

            // for (let i = 0; i < vm.pages[vm.$route.params.lang].length; i++)
            for (let i = 0; i < vm.pages.length; i++)
            {
                // let p = vm.pages[vm.$route.params.lang][i];
                let p = vm.pages[i];

                if (p.id == vm.page.variables.ctaLink2)
                {
                    cta = p;
                }
            }        

            return cta;
        },


        featuredProducts()
        {
            let fp = [];

            for (let i = 0; i < vm.photos.length; i++)
            {
                let p = vm.photos[i];

                if (p.variables.featured && p.variables.featured == 1)
                {
                    fp.push(p);
                }
            }

            fp = fp.slice(-6);

            return fp;
        },


        hasImage()
        {
            return Object.prototype.hasOwnProperty.call(vm.page.variables, 'heroSmall');
        },


        images()
        {
            if (!vm.hasImage) return '';

            let imgs = [],
                sizes = [
                    {
                        key: 'heroSmall',
                        'restriction': '(max-width: 1023px)',
                    },
                    {
                        key: 'heroMedium',
                        'restriction': '(min-width: 1024px) and (max-width: 1919px)',
                    },
                    {
                        key: 'heroLarge',
                        'restriction': '(min-width: 1920px)',
                    },
                ];

            for (let i = 0; i < sizes.length; i++)
            {
                let sz = sizes[i],
                    tv = vm.webp ? sz.key : sz.key + 'Fallback',
                    url = vm.page.variables[tv];
                
                if (url)
                {
                    imgs.push({
                        url: url,
                        restriction: sz.restriction
                    });
                }
            }

            return imgs;
        },


        // lang()
        // {
        //     return vm.$route.params.lang;
        // },
    },


	setup()
	{
		const { getImgUrl, getTranslation, to } = useFunctions();


		return {
			getImgUrl,
            getTranslation,
            to,
		};
	},


    created()
    {
        vm = this;
    },


    mounted() {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })
    },
}
</script>