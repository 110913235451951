<template>
    <a href="#" :class="classes" @click.prevent="toggle" aria-label="Menu">
        <span></span>
        <span></span>
        <span></span>
    </a>
</template>


<script>
var vm;

export default {
    props: {
        showNav: Boolean,
    },


    computed: {
        classes()
        {
            let cl = ['menu-toggle'];

            if (vm.showNav) cl.push('open');

            return cl.join(' ');
        },
    },


    methods: {
        toggle()
        {
            vm.$emit('toggle');
        },
    },


    created()
    {
        vm = this;
    },
}
</script>