<template>
    <div class="before-after" @mousemove="updateAfter($event, 'mouse')" @touchmove="updateAfter($event, 'touch')">
        <picture class="before">
            <source :srcset="imgBase + image.variables.beforeImage" />

            <img :loading="lazy ? 'lazy' : 'eager'" :src="imgBase + image.variables.beforeFallback" :alt="[image.seotitle, siteName].join(' by ')" :width="width" />
        </picture>

        <picture class="after">
            <source :srcset="imgBase + image.variables.afterImage" />

            <img :loading="lazy ? 'lazy' : 'eager'" :src="imgBase + image.variables.afterFallback" :alt="[image.seotitle, siteName].join(' by ')" :width="width" />
        </picture>

        <div class="cursor">
            <div class="icon">
                <font-awesome-icon icon="fa-sharp fa-regular fa-arrows-left-right" />
            </div>
        </div>

        <strong>
            {{ image.seotitle }}

            <a href="#" @click.prevent="showTooltip = !showTooltip">
                <font-awesome-icon v-if="image.description.length > 0" icon="fa-solid fa-circle-question" />

                <small v-if="image.description.length > 0" :class="{ active: showTooltip }" v-html="image.description.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2')" />
            </a>
        </strong>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

var vm;

export default {
    props: {
        image: Object,
        lazy: Boolean,
    },


    data()
    {
        return {
            showTooltip: false,
            width: 520,
        };
    },


    computed: {
        ...mapGetters([
            'imgBase',
            'siteName',
        ]),
    },


    methods: {
        getBAEl(el)
        {
            while (el.tagName.toLowerCase() != 'div' || (el.tagName.toLowerCase() == 'div' && !el.className.includes('before-after')))
            {
                el = el.parentNode;
            }

            return el;
        },


        updateAfter(e, type)
        {
            let crsX = type == 'touch' ? e.targetTouches[0].clientX : e.clientX,
                el = vm.getBAEl(e.target),
                elX = crsX - el.offsetLeft,
                w = el.clientWidth,
                x = Math.round((1 - (elX / w)) * 100);
            
            if (x < 0) x = 0;
            if (x > 100) x = 100;

            el.style = `--after-width: ${x}%`;
        },
    },


    created()
    {
        vm = this;
    },
}
</script>