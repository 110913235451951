import { computed } from 'vue';
// import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import useFunctions from '@/hooks/functions.js';

export default function useCart() {
	// const route = useRoute();
	const store = useStore();
    const functions = useFunctions();


    const allProducts = computed(() => {
        return store.getters.allProducts;
    });

	const base = computed(() => {
        return store.getters.base;
	});

    const cart = computed(() => {
        return store.getters.cart;
    });

    const discount = computed(() => {
        return store.getters.discount;
    });

    const downloads = computed(() => {
        return store.getters.downloads;
    });

    const imgBase = computed(() => {
        return store.getters.imgBase;
	});

    const photos = computed(() => {
        return store.getters.photos;
	});

    const prints = computed(() => {
        return store.getters.prints;
	});


    const cartInfo = computed(() => {
        let s = 0,
            t = 0;

        for (let i = 0; i < cart.value.length; i++)
        {
            let ci = cart.value[i],
                settings = getCartItemSettings(ci, 'object');

            s += ci.quantity;
            t += (settings.quantity * settings.price)
        }

        return {
            size: s,
            total: t,
        };
    });


    const discountTotal = computed(() => {
        if (discount.value === null || cartInfo.value.total === 0) return 0;

        let t = discount.value.type,
            v = discount.value.value * -1; // Always make sure the discount is a negative number
        
        // If it's a percentage type, calculate the percentage
        if (t == 'PERC')
        {
            return (cartInfo.value.total / 100) * v;
        }
        
        // Otherwise, just convert the euro value to cents
        return v * 100;
    });


    const orderTotal = computed(() => {
        let ot = cartInfo.value.total + discountTotal.value;

        return ot < 0 ? 0 : ot;
    });


    function getCartItemName(ci)
    {
        let obj = ci.photo == 0 ? getProductById(ci.product) : getPhotoById(ci.photo),
            name = obj.pagetitle;

        if (ci.photo != 0)
        {
            let print = getPrintById(ci.product);

            name += ' (' + print.pagetitle + ')';
        }
        
        return name;
    }


    function getCartItemSettings(ci, rtrn = 'string')
    {
        let isPrint = true,
            photo,
            product;
        
        if (ci.photo == 0)
        {
            photo = product = getProductById(ci.product);
            isPrint = false;
        }
        else
        {
            photo = getPhotoById(ci.photo);
            product = getPrintById(ci.product);
        }

        let routeName = 'PrintPage';

        if (!isPrint)
        {
            // routeName = ci.digital == 1 ? 'PresetPage' : 'Product' + product.language.toUpperCase();
            routeName = ci.digital == 1 ? 'PresetPage' : 'ProductEN';
        }

        if (rtrn == 'route')
        {
            let r = {
                name: routeName,
                params: {
                    alias: photo.alias,
                    // lang: 'en',
                    // lang: photo.language,
                }
            };

            return r;
        }
        
        let itemPrice = product.variables.price;

        if (product.variables.onSale && product.variables.onSale == 1)
        {
            itemPrice = product.variables.salePrice;
        }
        else if (photo.variables.onSale && photo.variables.onSale == 1)
        {
            itemPrice = product.variables.salePrice;
        }

        let settings = rtrn == 'object' ? {
            img: imgBase.value + '../' + photo.variables.thumbnail,
            price: parseInt(itemPrice),
            quantity: ci.quantity,
        } : [
            "--img: url('" + base.value + '../' + photo.variables.thumbnail + "')",
            '--price: "' + functions.asMoney(itemPrice) + '"',
            '--quantity: "' + ci.quantity + '"',
        ];

        return rtrn == 'string' ? settings.join('; ') : settings;
    }


    function getDownloadById(id)
    {
        for (let i = 0; i < downloads.value.length; i++)
        {
            let p = downloads.value[i];

            if (p.id == id) return p;
        }

        return false;
    }


    function getPhotoById(id)
    {
        for (let i = 0; i < photos.value.length; i++)
        {
            let p = photos.value[i];

            if (p.id == id) return p;
        }

        return false;
    }


    function getPrintById(id)
    {
        let keys = Object.keys(prints.value);

        for (let i = 0; i < keys.length; i++)
        {
            let p = prints.value[keys[i]];

            if (p.id == id) return p;
        }

        return false;
    }


    function getProductById(id)
    {
        // First, look in the products
        for (let i = 0; i < allProducts.value.length; i++)
        {
            let p = allProducts.value[i];

            if (p.id == id) return p;
        }


        // If no match was found, look at the downloads
        let dl = getDownloadById(id);
        
        if (dl !== false) return dl;

        
        return false;
    }
	

	return {
        cartInfo,
        discountTotal,
		getCartItemName,
        getCartItemSettings,
        orderTotal,
        // getPhotoById,
        // getPrintById,
        // getProductById,
	};
}