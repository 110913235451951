<template>
    <figure :class="productClasses">
        <picture>
            <source v-for="(sz, id) in imgSizes" :key="id" :media="sz.restriction"
                :srcset="imgBase + photo.variables[sz.key]" />
    
            <img :loading="lazy ? 'lazy' : 'eager'" :src="imgBase + photo.variables.imageFallback" :alt="[photo.seotitle, siteName].join(' by ')" :width="width" :height="height" />
        </picture>
    
        <slot></slot>
    </figure>
</template>


<script>
import { mapGetters } from 'vuex';

var vm;

export default {
    props: {
        lazy: {
            type: Boolean,
            default: false,
        },
        photo: Object,
        preview: {
            type: Boolean,
            default: false,
        },
    },


    data()
    {
        return {
            width: 520,
        };
    },


    computed: {
        ...mapGetters([
            'imgBase',
            'siteName',
            'sizes',
        ]),


        height()
        {
            return vm.width / 2 * 3;
        },


        imgSizes()
        {
            return vm.preview ? [
                {
                    'key': 'imageSmall',
                    'restriction': '(min-width: 1px)',
                },
            ] : vm.sizes;
        },


        productClasses()
        {
            let size = '2x3';
            
            switch (vm.photo.template)
            {
                case 8:
                    size = vm.photo.variables.imgRatio;
                    break;

                case 30:
                    size = '4x5';
                    break;
            }

            return ['size', size].join('-');
        },
    },


    created()
    {
        vm = this;
    },
}
</script>