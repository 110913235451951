<template>

    <header>
        <router-link :to="{ name: 'HomePage' }" class="logo">
            <img src="images/logo-mark.svg" :alt="siteName" width="30" height="30" />
        </router-link>

        <nav :class="showNav ? 'open' : ''">
            <div>
                <router-link v-for="(item, id) in nav" :key="['item', id].join('-')" :to="to(item, language)">
                    {{ item.name }}
                </router-link>
            </div>
        </nav>


        <!-- <aside v-if="languages.length > 1">
            <router-link v-for="(l, id) in otherLanguages" :to="langSwitch(l)" :key="['menu', id].join('-')" :class="l == $route.params.lang ? 'router-link-active' : ''">
                {{ l }}
            </router-link>
        </aside> -->


        <my-account :showTab="showAccountTab" @toggleTab="toggleTab" @logout="logoutUser" />


        <cart-preview :showTab="showCartTab" @toggleTab="toggleTab" />


        <toggle :showNav="showNav" @toggle="toggleNav" />

    </header>
    
</template>


<script>
import { mapGetters } from 'vuex';

import pageData from '@/hooks/page.js';
import useFunctions from '@/hooks/functions.js';

import slideTo from '@/mixins/slideTo';

import CartPreview from '@/components/CartPreview.vue';
import MyAccount from '@/components/MyAccount.vue';
import Toggle from '@/components/MenuToggle.vue';

var vm;

export default {
    components: {
        CartPreview,
        MyAccount,
		Toggle,
	},


	mixins: [
        slideTo,
    ],


    props: {
        showAccountTab: Boolean,
        showCartTab: Boolean,
        showNav: Boolean,
    },
    
    
    computed: {
        ...mapGetters([
            'isDev',
            'languages',
            'pages',
            'siteName',
            'structure',
        ]),


        // language()
        // {
        //     return vm.$route.params.lang;
        // },


        nav()
        {
            let nav = [];

            for (let i = 0; i < vm.structure.length; i++)
            {
                let item = vm.structure[i];

                if (item.hidemenu == 0)
                {
                    // if (vm.isDev || (!vm.isDev && ['fotoproducten', 'photo-gifts', 'sets'].indexOf(item.alias) < 0))
                    if (vm.isDev || (!vm.isDev && ['sets'].indexOf(item.alias) < 0))
                    {
                        nav.push(item);
                    }
                }
            }

            return nav;
        },


        // otherLanguages()
        // {
        //     let ol = [];

        //     for (let i = 0; i < vm.languages.length; i++)
        //     {
        //         let l = vm.languages[i];
        //         if (l != vm.language)
        //         {
        //             ol.push(l);
        //         }
        //     }

        //     return ol;
        // },
    },


    methods: {
        // langSwitch(lang)
		// {
		// 	// If the language is the current language, return the current route
		// 	if (lang == vm.language) return vm.$route;


        //     // Sometimes it's already enough to change the language parameter in the current route
        //     if (['PrintPage', 'PresetPage', 'SetPage'].indexOf(vm.$route.name) >= 0)
        //     {
        //         return {
        //             name: vm.$route.name,
        //             params: {
        //                 lang: lang,
        //             },
        //         };
        //     }
        //     else
        //     {
        //         // Get the variables
        //         let vars = typeof(vm.page) == 'object' && Object.keys(vm.page).length > 0 ? Object.keys(vm.page.variables) : [];
    
        //         // If there's a translation set, get the goto route
        //         if (vars.indexOf('translations') >= 0)
        //         {
        //             let tr = vm.page.variables.translations.split('||');
                    
        //             for (let i = 0; i < vm.pages[lang].length; i++)
        //             {
        //                 let p = vm.pages[lang][i];
                        
        //                 if (tr.indexOf(p.id) >= 0)
        //                 {
        //                     return vm.to(p, lang);
        //                 }
        //             }
        //         }
        //     }


		// 	// By default, just return to the home of the prefered language
		// 	return {
		// 		name: 'HomePage',
		// 		params: {
		// 			lang: lang,
		// 		},
		// 	}
		// },


        logoutUser()
        {
            vm.$emit('logout');
        },


        toggleNav()
        {
            vm.$emit('toggle');
        },


        toggleTab(id)
        {
            vm.$emit('toggleTab', id);
        },
    },


	setup()
	{
		const { page } = pageData();
		const { to } = useFunctions();

		return {
			page,
            to,
		};
	},


    created()
    {
        vm = this;
    },    
}
</script>