<template>
    <ul class="select-box" :class="open ? 'open' : ''">
        
        <li class="value" @click="open = !open">
            <span>
                {{ getOptionText(selected) }}
            </span>

            <font-awesome-icon icon="fa-solid fa-caret-down" v-if="items.length > 1 || Object.keys(items).length > 1" />
        </li>

        <li v-for="(o, id) in options" :key="[prefix, id].join('-')" :class="o == selected ? 'selected' : ''" @click="setSelected(o)">
            <span>
                {{ getOptionText(o) }}
            </span>
        </li>

    </ul>
</template>


<script>
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        items: Array,
        prefix: {
            type: String,
            default: '',
        },
        selected: {
            type: [String, null],
            default: null,
        },
        translate: {
            type: Boolean,
            default: true,
        },
        trim: {
            type: String,
            default: '',
        },
    },


    data()
    {
        return {
            open: false,
        };
    },


    computed: {
        options()
        {
            return vm.items.length > 1 || Object.keys(vm.items).length > 1 ? vm.items: [];
        },
    },


    methods: {
        getOptionText(o)
        {
            if (!vm.translate) return o;

            let ot;

            if (['mat', 'size'].indexOf(vm.prefix) >= 0)
            {
                ot = o.pagetitle;
            }
            else
            {
                let tag = vm.prefix != '' ? [vm.prefix, o].join('_') : o;
    
                ot = vm.getTranslation(tag);
            }

            return ot.replace(vm.trim, '');
        },
        
        
        setSelected(value)
        {
            let selected = value;

            vm.$emit('select', selected);
        },
    },

    
    setup()
	{
		const { getTranslation } = useFunctions();


		return {
            getTranslation,
		};
	},


    created()
    {
        vm = this;
    },


    watch: {
        selected()
        {
            vm.open = false;
        },
    }
}
</script>