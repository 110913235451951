<template>
    <div class="content">
        <form>
            
            <LoaderElement v-if="processing" />

            <ul >
                <li>
                    <input type="text" v-model="form.name" :placeholder="getTranslation('placeholder_name')" />
                </li>
                <li>
                    <input type="email" v-model="form.email" :placeholder="getTranslation('placeholder_email')" />
                </li>
                <li>
                    <input type="checkbox" id="new-pw" v-model="newPw" />

                    <font-awesome-icon :icon="newPw ? 'fa-solid fa-square' : 'fa-regular fa-square'" class="checkbox" @click="newPw = !newPw" />

                    <label for="new-pw">
                        {{ getTranslation('label_change_password') }}
                    </label>
                </li>

                <li v-if="newPw">
                    <input :type="pwType" class="password" v-model="form.password" :placeholder="getTranslation('placeholder_password_old')" />
                    <font-awesome-icon :icon="showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'" class="checkbox" @click="showPassword = !showPassword" />
                </li>

                <li v-if="newPw">
                    <input :type="npwType1" class="password" v-model="form.password_new_1" :placeholder="getTranslation('placeholder_password_new_1')" />
                    <font-awesome-icon :icon="showNew1 ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'" class="checkbox" @click="showNew1 = !showNew1" />

                    <FeedbackBox class="warning" :show="form.password_new_1.length > 0 && !isValidPassword(form.password_new_1)" v-html="getTranslation('password_criteria')" />
                </li>
                <li v-if="newPw">
                    <input :type="npwType2" class="password" v-model="form.password_new_2" :placeholder="getTranslation('placeholder_password_new_2')" />
                    <font-awesome-icon :icon="showNew2 ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'" class="checkbox" @click="showNew2 = !showNew2" />
                    
                    <FeedbackBox class="warning" :show="form.password_new_2.length > 0 && !newPwMatch" v-html="getTranslation('warning_new_password_mismatch')" />
                </li>

                <li>
                    <a href="#" class="button" :class="{ disabled: !btnState }" @click.prevent="submitForm">
                        {{ getTranslation('save') }}
                    </a>
                </li>

                <li>
                    <FeedbackBox class="warning" :closeable="true" :show="warning" @close="hideWarning">
                        {{ getTranslation('warning_invalid_form') }}
                    </FeedbackBox>

                    <FeedbackBox class="success" :closeable="true" :show="success === true" @close="hideSuccess">
                        {{ getTranslation('edit_save_success') }}
                    </FeedbackBox>

                    <FeedbackBox class="warning" :closeable="true" :show="success === false" @close="hideSuccess">
                        {{ getTranslation('edit_save_fail') }}
                    </FeedbackBox>
                </li>

            </ul>
        </form>

        <form v-if="activeAddresses.length > 0">
            <h2>
                {{ getTranslation('my_addresses') }}
            </h2>

            <div class="addresses wide">

                <transition-group name="fade" class="addresses wide">

                    <AddressPreview class="boxed" v-for="(a, id) in activeAddresses" :key="id" :address="a" :extended="true">
                        <a href="#" class="delete" :title="getTranslation('tip_delete_address')" @click.prevent="deleteAddress(a.id)">
                            <font-awesome-icon icon="fa-duotone fa-times-circle" />
                        </a>
                    </AddressPreview>

                </transition-group>

            </div>

        </form>
    </div>
</template>


<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import AddressPreview from '@/components/AddressPreview.vue';
import FeedbackBox from '@/components/FeedbackBox.vue';
import LoaderElement from '@/components/LoaderElement.vue';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        AddressPreview,
        FeedbackBox,
        LoaderElement,
    },


    data()
    {
        return {
            form: {
                name: '',
                email: '',
                password: '',
                password_new_1: '',
                password_new_2: '',
            },
            // lang: this.$route.params.lang,
            lang: 'en',
            newPw: false,
            pmMinLength: 8,
            processed: false,
            showNew1: false,
            showNew2: false,
            showPassword: false,
			submitted: false,
			success: null,
            warning: false,
        };
    },


    computed: {
        ...mapGetters([
            'user',
        ]),


        activeAddresses()
        {
            let addresses = [];

            for (let i = 0; i < vm.user.addresses.length; i++)
            {
                let a = vm.user.addresses[i];

                if (a.deleted == 0) addresses.push(a);
            }

            return addresses;
        },


        btnState()
        {
            if (vm.form.name.length <= 0) return false;
            if (vm.form.email.length <= 0 || !vm.isValidEmail(vm.form.email)) return false;
            
            if (vm.newPw)
            {
                if (vm.form.password.length == 0 || !vm.isValidPassword(vm.form.password_new_1) || !vm.newPwMatch)
                {
                    return false;
                }
                else if (vm.form.password == vm.form.password_new_1 || vm.form.password == vm.form.password_new_2)
                {
                    return false;
                }
            }

            return true;
        },


        newPwMatch()
        {
            return vm.form.password_new_1 == vm.form.password_new_2;
        },


        npwType1()
        {
            return vm.showNew1 ? 'text' : 'password';
        },


        npwType2()
        {
            return vm.showNew2 ? 'text' : 'password';
        },


		processing()
		{
			return vm.processed !== vm.submitted;
		},


        pwType()
        {
            return vm.showPassword ? 'text' : 'password';
        },
    },


    methods: {
        async deleteAddress(id)
		{
            if (confirm(vm.getTranslation('confirm_delete_address')))
            {
                vm.submitted = true;

                let formData = new FormData(),
                    ua,
                    uid;
                
                for (let i = 0; i < vm.user.addresses.length; i++)
                {
                    let a = vm.user.addresses[i];

                    if (a.id == id)
                    {
                        ua = vm.user.addresses[id];
                        uid = i;
                    }
                }
                
                ua = vm.jsCopy(ua);
                ua.deleted = 1;

                formData.append('action', 'addressUpdate');
                formData.append('data', JSON.stringify(ua));

                if (process.env.NODE_ENV == 'development')
                {
                    setTimeout(() => {
                        vm.user.addresses[uid] = ua;
                        vm.processed = true;
                    }, 2600);
                }
                else
                {
                    await axios.post(vm.base + 'api/forms/', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(() => {
                        vm.processed = true;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
                }
            }
		},

        
        async submitForm()
		{
            if (vm.btnState)
            {
                vm.submitted = true;

                let formData = new FormData();

                // for (let i = 0; i < vm.files.length; i++)
                // {
                // 	let f = vm.files[i];
                //
                // 	formData.append('files[' + i + ']', f);
                // }

                formData.append('action', 'user');
                formData.append('data', JSON.stringify(vm.form));

                if (process.env.NODE_ENV == 'development')
                {
                    setTimeout(() => {
                        vm.success = true;
                        vm.processed = true;
                    }, 2600);
                }
                else
                {
                    await axios.post(vm.base + 'api/forms/', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(response => {
                        vm.processed = true;
                        vm.success = response.data.success;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
                }
            }
            // If the button is disabled, show the warning 
            else
            {
                vm.warning = true;
            }
		},

        
        hideSuccess()
        {
            vm.success = null;
        },

        
        hideWarning()
        {
            vm.warning = false;
        },
    },

    
    setup()
	{
		const { getTranslation, isValidEmail, isValidPassword, jsCopy } = useFunctions();


		return {
            getTranslation,
            isValidEmail,
            isValidPassword,
            jsCopy,
		};
	},


    created()
    {
        vm = this;

        if (vm.user)
        {
            let f = vm.jsCopy(vm.user);
            f.password = '';
            f.password_new_1 = '';
            f.password_new_2 = '';
            vm.form = f;
        }
    },


    watch: {
        success(to)
        {
            if (to)
            {
                setTimeout(() => {
                    vm.success = null;
                }, 3900);
            }
        },
    }
}
</script>