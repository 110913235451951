import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueGtag from "vue-gtag";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import { faCircleArrowLeft, faCircleArrowRight, faSquare as fadSquare, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons'

import { faCartPlus, faShoppingBasket, faSquare as farSquare, faUser } from '@fortawesome/pro-regular-svg-icons'

import { faAngleLeft, faAngleRight, faArrowLeftLongToLine, faCaretDown, faCheck, faCircle, faCircleQuestion, faChevronDoubleLeft, faEye, faEyeSlash, faFilter, faMinus, faPaperPlane, faPlus, faSquare as fasSquare, faTimes, faWallet } from '@fortawesome/pro-solid-svg-icons'

import { faAngleDown, faArrowsLeftRight, faCropAlt, faSpinnerThird } from '@fortawesome/sharp-regular-svg-icons'

import { faArrowDownToLine, faBookmark, faHashtag, faHouseChimney, faImagePortrait, faMagnifyingGlass, faMapMarkerAlt } from '@fortawesome/sharp-solid-svg-icons'

/* add icons to the library */
library.add(
    faInstagram,
    faCircleArrowLeft, faCircleArrowRight, fadSquare, faTimesCircle,
    faCartPlus, faShoppingBasket, farSquare, faUser,
    faAngleLeft, faAngleRight, faArrowLeftLongToLine, faCaretDown, faCheck, faCircle, faCircleQuestion, faChevronDoubleLeft, faEye, faEyeSlash, faFilter, faMinus, faPaperPlane, faPlus, fasSquare, faTimes, faWallet,
    faAngleDown, faArrowsLeftRight, faCropAlt, faSpinnerThird,
    faArrowDownToLine, faBookmark, faHashtag, faHouseChimney, faImagePortrait, faMagnifyingGlass, faMapMarkerAlt,
)

createApp(App).use(store).use(router).use(VueGtag, {
    config: {
        id: "G-4NLFW8VV73",
        anonymize_ip: true,
    },
}).component('font-awesome-icon', FontAwesomeIcon).mount('#app')