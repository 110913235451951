<template>

    <div id="smooth-wrapper">
        <div id="smooth-content">
            
            <main>
                <section class="columns">
        
                    <div class="text">
        
                        <h1 v-html="page.pagetitle" />
                        <h2 v-if="page.longtitle.length > 0" v-html="page.longtitle" />
        
                        <div v-html="page.content" />
        
                    </div>
        
        
                    <div class="image">
                        <contact-form :page="page" :title="formTitle" />
                    </div>
        
                </section>
            </main>


            <slot name="footer" />
        </div>
    </div>

</template>


<script>
import { mapGetters } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import ContactForm from '@/components/ContactForm.vue';

var vm;

export default {
    components: {
        ContactForm,
    },


    props: {
        page: Object,
    },


    data()
    {
        return {
            smoother: null,
        }
    },


    computed: {
        ...mapGetters([
            'base',
            'pages',
        ]),


        formTitle()
        {
            let ft = false;

            if (typeof(vm.page.variables.sectionTitle) == 'string')
            {
                ft = vm.page.variables.sectionTitle;
            }

            return ft;
        },


        hasImage()
        {
            return Object.prototype.hasOwnProperty.call(vm.page.variables, 'image');
        },


        image()
        {
            return vm.hasImage ? "background-image: url('" + vm.base + vm.page.variables.image + "')" : '';
        },


        lang()
        {
            return vm.$route.params.lang;
        },
    },


    methods: {
        to(id)
        {
            let pages = vm.pages[vm.lang];

            for (let i = 0; i < pages.length; i++)
            {
                let p = pages[i];

                if (p.id == id) return p.path;
            }

            return vm.$route;
        },
    },


    created()
    {
        vm = this;
    },


    mounted()
    {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)
    
            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })


        document.querySelectorAll('.columns .text a[href^="#"]').forEach((a) => {
            a.addEventListener('click', (e) => {
                e.preventDefault();
                
                let id = e.currentTarget.href.split('#').slice(-1)[0],
                    target = document.getElementById(id);
                
                target.scrollIntoView();
            })
        });
    },
}
</script>