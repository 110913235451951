<template>
    <transition name="alert">
        <div class="feedback" :class="{ closeable }" v-if="show" @click="onClick">
            <font-awesome-icon v-if="closeable" icon="fa-duotone fa-times-circle" />
            
            <slot></slot>
        </div>
    </transition>
</template>


<script>
var vm;

export default {
    props: {
        closeable: {
            type: Boolean,
            default: false,
        },
        show: Boolean,
    },


    methods: {
        onClick()
        {
            if (vm.closeable)
            {
                vm.$emit('close');
            }
        },
    },


    created()
    {
        vm = this;
    },
}
</script>