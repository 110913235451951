<template>
    <div id="account" class="social has-tab">
        <a href="#" class="social has-tab" :title="getTranslation('tip_account')" @click.prevent="onClick">
            <font-awesome-icon icon="fa-regular fa-user fa-lg" />
        </a>
    
            
        <transition name="reveal-cart">
        
            <aside v-if="showTab">
                
                <LoginForm v-if="!user" :loginTab="loginTab" :togglePreText="true" @toggle="setLoginTab">
                    <h3 v-if="loginTab === 1">
                        {{ getTranslation('register') }}
                    </h3>

                    <h3 v-else-if="loginTab === 2">
                        {{ getTranslation('reset_pw') }}
                    </h3>

                    <h3 v-else>
                        {{ getTranslation('login') }}
                    </h3>
                </LoginForm>

                <form v-else>
                    <AccountNav :class="{ open: showTab }" @logout="$emit('logout')" />
                </form>

            </aside>

        </transition>

    </div>
</template>


<script>
import { mapGetters } from 'vuex';

import AccountNav from '@/components/AccountNav.vue';
import LoginForm from '@/components/LoginForm.vue';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    components: {
        AccountNav,
        LoginForm,
    },


    props: {
        showTab: Boolean,
    },


    data()
    {
        return {
            loginTab: 0,
        }
    },


    computed: {
        ...mapGetters([
            'user',
        ]),
    },


    methods: {
        onClick({target})
        {
            let tag = target.nodeName.toLowerCase();

            if (tag == 'path')
            {
                target = target.parentNode;
                tag = target.nodeName.toLowerCase();
            }

            if (
                (tag == 'a' && target.className.indexOf('has-tab') >= 0) ||
                (tag == 'i' && target.className.indexOf('fa-eye') < 0) ||
                (tag == 'svg' && target.className.baseVal.indexOf('fa-eye') < 0)
            )
            {
                vm.$emit('toggleTab', 'account');
            }
        },


        setLoginTab(tab)
        {
            vm.loginTab = tab;
        },
    },

    
    setup()
	{
		const { getTranslation } = useFunctions();


		return {
            getTranslation,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>