<template>
    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main>
        
                <section class="cart">
        
                    <h1>
                        {{ page.title }}
                    </h1>
        
                    <div v-if="cart.length > 0" class="container">
        
                        <ul class="cart-items">
                            <li v-for="(ci, id) in cart" :key="id" :style="getCartItemSettings(ci)">
                                <div class="img">
                                    <figure class="size-1x1" />
                                </div>
        
                                <router-link class="name" :to="getCartItemSettings(ci, 'route')" v-html="getCartItemName(ci)" />
        
                                <strong class="price">
                                    {{ currency }}
                                </strong>
        
                                <span class="quantity-tweak">
                                    <a href="#" @click.prevent="updateQuantity(id, -1)">
                                        <font-awesome-icon icon="fa-solid fa-minus" />
                                    </a>
        
                                    <span class="quantity"></span>
        
                                    <a href="#" @click.prevent="updateQuantity(id, 1)">
                                        <font-awesome-icon icon="fa-solid fa-plus" />
                                    </a>
                                </span>
        
                                <small @click="removeCartItem(id)">
                                    {{ getTranslation('delete') }}
                                </small>
                            </li>
                        </ul>
        
        
                        <div class="summary">
                            <h2>
                                {{ getTranslation('order_totals') }}
                            </h2>
        
                            <div class="totals" v-if="discount !== null">
                                <span>
                                    {{ getTranslation('subtotal') }}:
                                </span>
        
                                <span>
                                    {{ asMoney(cartInfo.total) }}
                                    {{ currency }}
                                </span>
                            </div>
        
                            <div class="totals" v-if="discount !== null">
                                <span>
                                    {{ getTranslation('discount') }}:
                                </span>
        
                                <span>
                                    {{ asMoney(discountTotal) }}
                                    {{ currency }}
                                </span>
                            </div>
        
                            <div class="totals">
                                <strong>
                                    {{ getTranslation('total') }}:
                                </strong>
        
                                <strong>
                                    {{ asMoney(orderTotal) }}
                                    {{ currency }}
                                </strong>
                            </div>
        
                            <div class="tools">
                                <router-link :to="orderLink" class="button add-to-cart">
                                    <font-awesome-icon icon="fa-solid fa-wallet" />
        
                                    {{ getTranslation('btn_order') }}
                                </router-link>
                            </div>
        
                            <discount v-if="discount === null" />
                        </div>
        
                    </div>
        
        
                    <em v-else>
                        {{ getTranslation('warning_empty_cart') }}
                    </em>
        
                </section>
        
            </main>
            

            <slot name="footer" />
        </div>
    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import useCart from '@/hooks/cart.js';
import useFunctions from '@/hooks/functions.js';

import Discount from '@/components/DiscountForm.vue';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        Discount,
    },


    data()
    {
        return {
            removeWarning: 'warning',
            smoother: null,
        };
    },


    computed: {
        ...mapGetters([
            'cart',
            'currency',
            'discount',
        ]),


        orderLink()
        {
            return {
                // name: 'Order' + vm.$route.params.lang.toUpperCase(),
                name: 'OrderEN',
            };
        },
    },


    methods: {
        ...mapActions([
            'addCartItems',
            'removeCartItem',
        ]),


        updateQuantity(id, modifier)
        {
            let ci = vm.cart[id],
                q = ci.quantity + modifier;
            
            if (q > 0)
            {
                vm.addCartItems([
                    {
                        photo: ci.photo,
                        product: ci.product,
                        quantity: modifier,
                    },
                ]);
            }
            else
            {
                vm.removeCartItem(id);
            }
        },
    },

    
    setup()
	{
		const { asMoney, getTranslation, to } = useFunctions();
        const { cartInfo, discountTotal, getCartItemName, getCartItemSettings, orderTotal } = useCart();


		return {
            asMoney,
            cartInfo,
            discountTotal,
            getCartItemName,
            getCartItemSettings,
            getTranslation,
            orderTotal,
            to,
		};
	},


    created()
    {
        vm = this;

        vm.removeWarning = vm.getTranslation('warning_remove_cart_item');
    },


    mounted()
    {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })
    },
}
</script>