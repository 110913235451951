<template>

    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main class="portfolio">
                <section>
                    <div>
                        <h1 v-html="page.pagetitle" />
                        <h2 v-if="page.longtitle.length > 0" v-html="page.longtitle" />
        
                        <div class="content" v-html="page.content" />
                    </div>
                </section>
        
        
                <section class="products gallery">
                    <div class="items" :key="$route.path">
        
                        <transition-group name="pop-in">
                            <product-preview v-for="(pr, id) in products" :key="['product', pr.alias].join('-')" :index="id"
                                :photo="pr" />
                        </transition-group>
        
                        <font-awesome-icon v-if="products.length === 0" icon="fa-sharp fa-regular fa-spinner-third" spin
                            size="2xl" />
        
                    </div>
                </section>
        
            </main>


            <slot name="footer" />
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import useFunctions from '@/hooks/functions.js';

import ProductPreview from '@/components/ProductPreview.vue';

var vm;

export default {
    props: {
        page: Object,
        photo: Object,
    },


    data()
    {
        return {
            smoother: null,
        }
    },


    components: {
        ProductPreview,
    },


    computed: {
        ...mapGetters([
            'sets',
        ]),


        products()
        {
            return vm.sets;
        },
    },


    setup() {
        const { getTranslation } = useFunctions();


        return {
            getTranslation,
        };
    },


    created() {
        vm = this;
    },


    mounted()
    {
        vm.$nextTick(() => {
            ScrollTrigger.normalizeScroll(true)

            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        })
    },
}
</script>