<template>
    <form class="contact-form" id="contact-form">
        
        <transition name="fade" mode="out-in">

            <loader-element v-if="processing" />

        </transition>

        <div v-if="success === false" class="feedback error" v-html="page.variables.errorMessage" />

        <div v-if="success" v-html="page.variables.successMessage" />

        <div v-else>
            <h1 v-if="title" v-html="title" />
            
            <ul>
                <li :class="contact.name.length > 0 ? 'filled' : ''">
                    <input type="text" v-model="contact.name" :placeholder="placeholders.name" />
                </li>
                
                <li :class="validEmail ? 'filled' : ''">
                    <input type="text" v-model="contact.email" :placeholder="placeholders.email" />
                </li>

                <li :class="contact.message.length > 0 ? 'filled' : ''">
                    <textarea v-model="contact.message" :placeholder="placeholders.message" />
                </li>

                <li>
                    <a href="#" :class="buttonClasses" @click.prevent="submitForm">
                        <span>
                            {{ placeholders.button }}
                        </span>

                        <font-awesome-icon icon="fa-solid fa-paper-plane" />
                    </a>
                </li>
            </ul>
        </div>
        
    </form>

</template>


<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import LoaderElement from '@/components/LoaderElement.vue';
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    components: {
		LoaderElement,
	},


    props: {
        page: Object,
        title: {
            type: [String, Boolean],
            default: false,
        },
    },


	data()
    {
        return {
            contact: {
                name: '',
                email: '',
                lang: 'en',
                // lang: this.$route.params.lang,
                message: '',
            },
            processed: false,
			submitted: false,
			success: null,
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'isDev',
        ]),


        buttonClasses()
        {
            let cl = ['button'];

            if (!vm.valid)
            {
                cl.push('disabled');
            }

            return cl.join(' ');
        },


        placeholders()
        {
            return {
                button: vm.getTranslation('placeholder_send'),
                email: vm.getTranslation('placeholder_email'),
                message: vm.getTranslation('placeholder_message'),
                name: vm.getTranslation('placeholder_name'),
            };
        },


		processing()
		{
			return vm.processed !== vm.submitted;
		},


        valid()
        {
            if (vm.contact.name.length === 0) return false;
            if (vm.contact.email.length === 0) return false;
            if (vm.contact.message.length === 0) return false;

            if(!vm.validEmail) return false;

            return true;
        },


        validEmail()
        {
            return vm.isValidEmail(vm.contact.email);
        },
    },


    methods: {
        async submitForm()
		{
			vm.submitted = true;

            let formData = {
                action: 'contact',
                data: JSON.stringify(vm.contact),
            };
            
			if (vm.isDev)
			{
				setTimeout(() => {
					vm.success = true;
					vm.processed = true;
				}, 2600);
			}
			else
			{
				await axios.post(vm.base + 'api/forms/', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				})
				.then(response => {
                    vm.processed = true;
					vm.success = response.data.success;
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},
    },

    
    setup()
	{
		const { getTranslation, isValidEmail } = useFunctions();


		return {
            getTranslation,
            isValidEmail,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>