<template>
    <li class="select">
        <label>
            <slot name="label"></slot>
        </label>
        
        <slot v-if="active" name="select"></slot>
        
        <span class="fake-select">
            <slot name="selected"></slot>
        </span>
        
        <font-awesome-icon v-if="active" icon="fa-sharp fa-regular fa-angle-down" />

        <small v-if="description">
            {{ description }}
        </small>
    </li>
</template>


<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: true,
        },
        description: {
            type: [String, null],
            default: null,
        },
    },
}
</script>