<template>
    <li class="list-item">
        <strong>
            {{ itemName }}
        </strong>

        <span>
            {{ asMoney(item.price * 100 )}}
            {{ currency }}
        </span>

        <span class="quantity" v-html="item.quantity" />
        
        <span v-html="itemTotal" />
    </li>
</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        item: Object,
    },


    computed: {
        ...mapGetters([
            'allProducts',
            'currency',
            'downloads',
            'pages',
            'photos',
            'prints',
        ]),

            
        itemName()
        {
            let name = [];

            if (vm.item.variant != 0)
            {
                for (let i = 0; i < vm.photos.length; i++)
                {
                    let p = vm.photos[i];

                    if (p.id == vm.item.variant) name.push(p.pagetitle);
                }
                
                let po = vm.getPrintOptionById(vm.item.product);
                name.push('(' + po.pagetitle + ')');
            }
            else
            {
                let products = vm.item.digital == 1 ? vm.downloads : vm.allProducts;

                for (let i = 0; i < products.length; i++)
                {
                    let p = products[i];

                    if (p.id == vm.item.product)
                    {
                        name.push(p.pagetitle);
                    }
                }
            }

            return name.join(' ');
        },

            
        itemTotal()
        {
            return [vm.asMoney(vm.item.quantity * vm.item.price * 100), vm.currency].join(' ');
        },
    },

    
    setup()
	{
		const { asMoney, getPrintOptionById } = useFunctions();


		return {
            asMoney,
            getPrintOptionById,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>